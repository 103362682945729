import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Marker } from "react-map-gl";
import { useTheme } from "styled-components";

import { LabelledInput } from "@components/FormControls";
import Icon from "@components/Icon";
import MapLocationEditor, {
  LongitudeLatitude,
} from "@components/MapLocationEditor";
import {
  WizardControlsContainer,
  WizardStepContainer,
} from "@components/ModalWizardHelper";
import { ModalDescription } from "@design/helpers";
import DS from "@design/system";
import { useStore, useUnitFull } from "@state/hooks";
import { useThemeHelper } from "@util/useThemeHelper";
import { AedInspection } from "@util/viewModel";

const LocationStep = () => {
  const { t } = useTranslation();
  const { palettes } = useTheme();
  const { icon } = useThemeHelper();

  const { formState, register, setValue, watch } =
    useFormContext<AedInspection>();

  const [controllerSerialNumber, newLongitude, newLatitude] = watch([
    "ControllerSerialNumber",
    "NewLongitude",
    "NewLatitude",
  ]);
  const { data: unit, isLoading } = useUnitFull(controllerSerialNumber);
  const { data: store } = useStore(unit?.StoreId);

  const handleLocationChange = useCallback(
    (location: LongitudeLatitude) => {
      setValue("NewLongitude", location.longitude);
      setValue("NewLatitude", location.latitude);
    },
    [setValue],
  );

  return (
    <WizardStepContainer>
      <ModalDescription>
        First, confirm or update the location information
      </ModalDescription>

      <WizardControlsContainer>
        <LabelledInput
          label="Location"
          loading={isLoading}
          {...register("NewLocation")}
        />
        <MapLocationEditor
          width={288}
          height={200}
          location={
            newLongitude && newLatitude
              ? { longitude: newLongitude, latitude: newLatitude }
              : undefined
          }
          resetLocation={
            formState.defaultValues &&
            formState.defaultValues.NewLongitude &&
            formState.defaultValues.NewLatitude
              ? {
                  longitude: formState.defaultValues.NewLongitude,
                  latitude: formState.defaultValues.NewLatitude,
                }
              : undefined
          }
          presets={
            store
              ? [
                  {
                    title: `Set to ${t(
                      "term.store_one",
                    ).toLowerCase()} location`,
                    icon: icon("store"),
                    location: {
                      longitude: store.LocationAddress.Longitude,
                      latitude: store.LocationAddress.Latitude,
                    },
                  },
                ]
              : undefined
          }
          markers={
            store
              ? [
                  <Marker
                    key={store.StoreId}
                    longitude={store.LocationAddress.Longitude}
                    latitude={store.LocationAddress.Latitude}
                  >
                    <div
                      style={{
                        padding: DS.margins.nano,
                        borderRadius: DS.radii.pill,
                        display: "grid",
                        placeItems: "center",
                        background: palettes.states.na.background,
                      }}
                    >
                      <Icon
                        name={icon("store")}
                        color={palettes.states.na.foreground}
                        size={16}
                      />
                    </div>
                  </Marker>,
                ]
              : undefined
          }
          onLocationChange={handleLocationChange}
        />
      </WizardControlsContainer>
    </WizardStepContainer>
  );
};

export default LocationStep;
