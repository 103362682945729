import { LngLatLike } from "mapbox-gl";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { MapRef } from "react-map-gl";

import { useMarkerState } from "./useMarkerState";
import { MarkerViewState } from "./useUserPreference";

export interface MapStateContext {
  setMapRef: (ref: MapRef) => void;

  groupId: string | null;
  setGroupId: React.Dispatch<React.SetStateAction<string | null>>;

  storeId: string | null;
  setStoreId: React.Dispatch<React.SetStateAction<string | null>>;

  flyTo: (coords: LngLatLike) => void;

  controllerSerialNumber: string | null;
  setControllerSerialNumber: React.Dispatch<
    React.SetStateAction<string | null>
  >;

  markerState: MarkerViewState;
  setMarkerViewState: (newValue: Partial<MarkerViewState>) => void;
}

const initialValue = createContext<MapStateContext>({
  setMapRef: () => null,

  groupId: null,
  setGroupId: () => null,

  storeId: null,
  setStoreId: () => null,

  flyTo: () => null,

  controllerSerialNumber: null,
  setControllerSerialNumber: () => null,

  markerState: { type: "unit", mode: "readiness" },
  setMarkerViewState: () => null,
});

export const useMapState = () => useContext(initialValue);

export const MapStateProvider = ({ children }: { children: ReactNode }) => {
  const [groupId, setGroupId] = useState<string | null>(null);
  const [storeId, setStoreId] = useState<string | null>(null);
  const [unitId, setUnitId] = useState<string | null>(null);
  const mapRef = useRef<MapRef | null>(null);

  const setMapRef = useCallback((el: MapRef | null) => {
    mapRef.current = el;
  }, []);

  const flyTo = useCallback((coords: LngLatLike) => {
    const MAP_ANIMATION_SPEED = 3;
    const ZOOMED_IN = 18;
    mapRef.current?.flyTo({
      speed: MAP_ANIMATION_SPEED,
      center: coords,
      zoom: ZOOMED_IN,
    });
  }, []);

  const [markerState, setMarkerViewState] = useMarkerState();

  return (
    <initialValue.Provider
      value={{
        setMapRef,
        groupId,
        setGroupId,
        storeId,
        setStoreId,
        flyTo,
        controllerSerialNumber: unitId,
        setControllerSerialNumber: setUnitId,
        markerState,
        setMarkerViewState,
      }}
    >
      {children}
    </initialValue.Provider>
  );
};
