import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export function useTerminologies() {
  const { t } = useTranslation();

  const categoryLabel = useCallback(
    (category?: Api.EventCategory) => {
      switch (category) {
        case "Uncategorized":
          return t("incidentCategory.uncategorized");
        case "Box of Items":
          return t("incidentCategory.boxOfItems");
        case "Burns":
          return t("incidentCategory.burn");
        case "Cleaning":
          return t("incidentCategory.cleaning");
        case "Cuts":
          return t("incidentCategory.cut");
        case "Hardware":
          return t("incidentCategory.hardware");
        case "Safety":
          return t("incidentCategory.safety");
        case "STF":
          return t("incidentCategory.stf");
        case "Tools":
          return t("incidentCategory.tools");
        case "Eyes":
          return t("incidentCategory.eye");
        default:
          return "Unknown";
      }
    },
    [t],
  );

  const severityLabel = useCallback(
    (severity: Api.EventSeverityLevel, truncate = false) => {
      switch (severity) {
        case "High":
          return t("severity.high");
        case "Medium":
          return truncate
            ? t("severity.medium").substring(0, 3) + "."
            : t("severity.medium");
        case "Low":
          return t("severity.low");
        default:
          return "Unknown";
      }
    },
    [t],
  );

  const getTitle = useCallback(
    (incident: Api.Incident, truncate = false) => {
      const severity =
        incident.incidentOverallSeverity ?? incident.severityLevel;
      const category = incident.category;

      return `${severityLabel(severity, truncate)} ${categoryLabel(category)}`;
    },
    [categoryLabel, severityLabel],
  );

  const getUnitType = useCallback(
    (unitType?: Api.UnitType, plural?: boolean) => {
      switch (unitType) {
        case "AutomatedExternalDefibrillator":
          return t(plural ? "term.aed_other" : "term.aed_one");
        case "FirstAidStation":
          return t(
            plural ? "term.firstAidStation_other" : "term.firstAidStation_one",
          );
        case "Cube":
          return t(plural ? "term.cube_other" : "term.cube_one");
        default:
          return "Unknown";
      }
    },
    [t],
  );

  const devicesOnlineCaption = useCallback(
    (online: number, total: number, offline: number) => {
      if (!offline) offline = total - online;

      if (total === 0)
        return t("metrics.unitsOnline.caption.noUnits", {
          defaultValue: "",
          count: total,
        });

      if (online === total)
        return t("metrics.unitsOnline.caption.allOnline", { count: online });
      if (online === 0)
        return t("metrics.unitsOnline.caption.allOffline", { count: offline });

      return t("metrics.unitsOnline.caption.someOffline", { count: offline });
    },
    [t],
  );

  const devicesReadyCaption = useCallback(
    (ready: number, total: number) => {
      if (total === 0) return t("metrics.unitsReady.caption.noUnits");
      if (ready === total)
        return t("metrics.unitsReady.caption.allReady", { count: ready });
      if (ready === 0)
        return t("metrics.unitsReady.caption.allNotReady", { count: total });

      return t("metrics.unitsReady.caption.someNotReady", {
        count: total - ready,
      });
    },
    [t],
  );

  return {
    categoryLabel,
    severityLabel,
    getTitle,
    getUnitType,
    devicesReadyCaption,
    devicesOnlineCaption,
  };
}
