import { motion } from "framer-motion";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { v4 as uuidv4 } from "uuid";

import DS from "@design/system";

const BEZ_CONSTANT = 0.552284749831;

const DURATION = 0.2;

const BORDER_THICKNESS = 2;
const SMALL_CIRCLE_RADIUS = 18;
const LARGE_CIRCLE_RADIUS = 48;
const PIN_RADIUS = 30;
const PIN_HEIGHT = 95;
const PIN_GAP = 3;

const CENTER = Math.max(SMALL_CIRCLE_RADIUS, LARGE_CIRCLE_RADIUS, PIN_RADIUS);

const width = CENTER * 2 + BORDER_THICKNESS * 2;
const height = PIN_HEIGHT + CENTER + PIN_GAP + BORDER_THICKNESS * 4 - 1;

function makeCirclePath(x: number, y: number, rx: number, ry: number = rx) {
  const b = rx * BEZ_CONSTANT;

  return `
  M ${x} ${y - ry}
  C ${x + b} ${y - ry} ${x + rx} ${y - b} ${x + rx} ${y}
  C ${x + rx} ${y + b} ${x + b} ${y + ry} ${x} ${y + ry}
  C ${x - b} ${y + ry} ${x - rx} ${y + b} ${x - rx} ${y}
  C ${x - rx} ${y - b} ${x - b} ${y - ry} ${x} ${y - ry}
  Z
`;
}

function makePinPath(
  x: number,
  y: number,
  radius: number,
  height: number,
  bulge: number,
  bulgeHeight: number,
) {
  const b = radius * BEZ_CONSTANT;

  return `
  M ${x} ${y - radius}
  C ${x + b} ${y - radius} ${x + radius} ${y - b} ${x + radius} ${y}
  C ${x + radius} ${y + b} ${x + bulge} ${height - bulgeHeight} ${x} ${height}
  C ${x - bulge} ${height - bulgeHeight} ${x - radius} ${y + b} ${
    x - radius
  } ${y}
  C ${x - radius} ${y - b} ${x - b} ${y - radius} ${x} ${y - radius}
  Z
`;
}

const selectedPin = makePinPath(
  width / 2,
  width / 2,
  PIN_RADIUS,
  PIN_HEIGHT,
  5,
  12,
);

const selectedTransition = makeCirclePath(
  width / 2,
  height - LARGE_CIRCLE_RADIUS - BORDER_THICKNESS - 10,
  13,
  18,
);

const singleCircle = makeCirclePath(
  width / 2,
  height - LARGE_CIRCLE_RADIUS - BORDER_THICKNESS,
  SMALL_CIRCLE_RADIUS,
);

const size = (count: number) => {
  if (count <= 5) {
    return LARGE_CIRCLE_RADIUS - 16;
  } else if (count <= 15) {
    return LARGE_CIRCLE_RADIUS - 12;
  } else {
    return LARGE_CIRCLE_RADIUS - 8;
  }
};

const dynamicClusterCircle = (count: number) =>
  makeCirclePath(
    width / 2,
    height - LARGE_CIRCLE_RADIUS - BORDER_THICKNESS,
    size(count),
  );

const PinContainer = styled.div`
  position: relative;
  pointer-events: none;
`;

export const PinContent = ({
  label,
  description,
  selected,
  cluster,
}: {
  label?: ReactNode;
  description?: ReactNode;
  selected: boolean;
  cluster?: number | null;
}) => (
  <div
    style={{
      textAlign: "center",
      fontSize: cluster ? 20 : selected ? 20 : 15,
      fontWeight: 700,
    }}
  >
    <div
      style={{
        lineHeight: "20px",
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {label}
    </div>
    {(selected || !!cluster) && (
      <div style={{ fontWeight: 400, fontSize: 10 }}>{description}</div>
    )}
  </div>
);

export const Pin = ({
  label,
  cluster,
  selected,
  content,
  background,
}: {
  label?: string | null;
  cluster?: number | null;
  selected: boolean;
  content?: (width: number, height: number) => ReactElement;
  background?: (width: number, height: number) => ReactElement;
}) => {
  const [id] = useState(uuidv4());

  const [state, setState] = useState<
    | "cluster"
    | "single"
    | "selected"
    | "selected-to-single"
    | "single-to-selected"
  >(cluster ? "cluster" : selected ? "selected" : "single");

  const { palettes } = useTheme();

  useEffect(() => {
    const newState = cluster ? "cluster" : selected ? "selected" : "single";

    setState((current) => {
      if (newState === current) return current;

      if (
        (current === "single" || current === "selected-to-single") &&
        newState === "selected"
      ) {
        return "single-to-selected";
      } else if (
        (current === "selected" || current === "single-to-selected") &&
        newState === "single"
      ) {
        return "selected-to-single";
      } else {
        return "cluster";
      }
    });
  }, [cluster, selected]);

  return (
    <PinContainer>
      <div
        style={{
          filter: selected ? "" : DS.shadowsFilter.main,
        }}
      >
        <svg
          style={{
            position: "absolute",
            left: "50%",
            transform: `translate(-50%, -${height - LARGE_CIRCLE_RADIUS}px)`,
          }}
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <motion.path
              id={`pin-${id}`}
              initial={{
                d:
                  state === "single"
                    ? singleCircle
                    : state === "selected"
                      ? selectedPin
                      : state === "selected-to-single"
                        ? singleCircle
                        : state === "single-to-selected"
                          ? selectedPin
                          : dynamicClusterCircle(cluster ?? 0),
                opacity: 1,
              }}
              animate={{
                d:
                  state === "single"
                    ? singleCircle
                    : state === "selected"
                      ? selectedPin
                      : state === "selected-to-single"
                        ? [null, selectedTransition, singleCircle]
                        : state === "single-to-selected"
                          ? [null, selectedTransition, selectedPin]
                          : dynamicClusterCircle(cluster ?? 0),
                opacity: 1,
              }}
              transition={{
                ease: ["easeIn", "easeOut"],
                duration: DURATION,
                delay: selected ? 0 : 0.05,
              }}
              style={{ cursor: "pointer", pointerEvents: "all" }}
            />

            <mask id={`pinOutside-${id}`}>
              <rect x={0} y={0} width={width} height={height} fill="white" />
              <use xlinkHref={`#pin-${id}`} fill="black" />
            </mask>

            <mask id={`pinInside-${id}`}>
              <rect x={0} y={0} width={width} height={height} fill="black" />
              <use xlinkHref={`#pin-${id}`} fill="white" />
            </mask>

            <filter id={`blur-${id}`} x="-10" y="-10" width={20} height={20}>
              <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
            </filter>
          </defs>

          <use
            xlinkHref={`#pin-${id}`}
            stroke={palettes.body.background}
            strokeWidth={BORDER_THICKNESS * 2}
            strokeLinejoin="round"
            mask={`url(#pinOutside-${id})`}
          />

          <g mask={`url(#pinInside-${id})`}>{background?.(width, height)}</g>

          <motion.ellipse
            cx={width / 2}
            cy={height - LARGE_CIRCLE_RADIUS - BORDER_THICKNESS + 4}
            fill={"rgba(0, 0, 0, 0.25)"}
            filter={`url(#blur-${id})`}
            animate={{
              opacity: selected ? 1 : 0,
              rx: selected ? 10 : 0,
              ry: selected ? 2 : 0,
            }}
            transition={{ delay: selected ? 0.1 : 0 }}
          />
          <motion.circle
            cx={width / 2}
            cy={height - LARGE_CIRCLE_RADIUS - BORDER_THICKNESS}
            fill={palettes.body.background}
            animate={{
              opacity: selected ? 1 : 0,
              r: selected ? 5 : 0,
            }}
            transition={{ delay: selected ? 0.1 : 0 }}
          />
        </svg>
      </div>

      {!cluster && (
        <motion.div
          style={{
            position: "absolute",
            left: "50%",
            minWidth: 150,
            maxWidth: 300,
            transform: "translateX(-50%)",
            textAlign: "center",
            fontSize: 14,
            fontWeight: 600,
            color: "black",
            WebkitTextStroke: "2px rgba(255, 255, 255, 0.6)",
            paintOrder: "stroke fill",
          }}
          initial={{
            top:
              (selected
                ? 0
                : cluster
                  ? LARGE_CIRCLE_RADIUS
                  : SMALL_CIRCLE_RADIUS) + DS.margins.nanoN,
          }}
          animate={{
            top:
              (selected
                ? 0
                : cluster
                  ? LARGE_CIRCLE_RADIUS
                  : SMALL_CIRCLE_RADIUS) + DS.margins.nanoN,
          }}
          transition={{
            ease: "easeInOut",
            duration: DURATION,
            delay: selected ? 0.05 : 0,
          }}
        >
          {label}
        </motion.div>
      )}

      <motion.div
        style={{
          position: "absolute",
          width:
            (selected
              ? PIN_RADIUS
              : cluster
                ? LARGE_CIRCLE_RADIUS
                : SMALL_CIRCLE_RADIUS) * 2,
          transform: "translate(-50%, -50%)",
        }}
        initial={{
          top: selected
            ? LARGE_CIRCLE_RADIUS -
              height +
              PIN_RADIUS +
              (LARGE_CIRCLE_RADIUS - PIN_RADIUS) +
              BORDER_THICKNESS
            : -BORDER_THICKNESS,
          width:
            (selected
              ? PIN_RADIUS
              : cluster
                ? LARGE_CIRCLE_RADIUS
                : SMALL_CIRCLE_RADIUS) * 2,
        }}
        animate={{
          top: selected
            ? LARGE_CIRCLE_RADIUS -
              height +
              PIN_RADIUS +
              (LARGE_CIRCLE_RADIUS - PIN_RADIUS) +
              BORDER_THICKNESS
            : -BORDER_THICKNESS,
          width:
            (selected
              ? PIN_RADIUS
              : cluster
                ? LARGE_CIRCLE_RADIUS
                : SMALL_CIRCLE_RADIUS) * 2,
          opacity: selected ? [null, 0, 0, 0, 1] : [1, 0, 0, 0, 1],
        }}
        transition={{ duration: DURATION }}
      >
        {content?.(
          (selected
            ? PIN_RADIUS
            : cluster
              ? LARGE_CIRCLE_RADIUS
              : SMALL_CIRCLE_RADIUS) * 2,
          height,
        )}
      </motion.div>
    </PinContainer>
  );
};
