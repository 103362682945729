import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import Icon from "@components/Icon";
import { useShipmentsByIds, useUnit } from "@state/hooks";
import { getShipmentStatusIcon, activeShipmentsOnly } from "@util/shipments";
import { useThemeHelper } from "@util/useThemeHelper";

import { Pin, PinContent } from "./Pin";

export const UnitShipmentMarker = ({
  id,
  selected,
  shipmentsIds,
  cluster,
}: {
  id: string;
  selected: boolean;
  shipmentsIds?: string | null;
  cluster?: number | null;
}) => {
  const { t } = useTranslation();
  const { palettes } = useTheme();
  const { shipmentStatusPalette } = useThemeHelper();

  const { data: unit, isLoading: isLoadingStore } = useUnit(id);
  const { data: shipments, isLoading: isLoadingShipments } = useShipmentsByIds(
    shipmentsIds?.split(",").filter((id) => !!id) ?? [],
    { numberDays: 90 },
  );

  const activeShipments = useMemo(
    () => shipments?.filter(activeShipmentsOnly),
    [shipments],
  );

  return (
    <Pin
      label={unit?.UnitName}
      selected={selected}
      cluster={cluster}
      content={() => {
        return (
          <PinContent
            label={
              <Icon
                name={
                  isLoadingStore || isLoadingShipments
                    ? "spinner"
                    : activeShipments && activeShipments[0]
                      ? getShipmentStatusIcon(activeShipments[0].Status)
                      : "minus"
                }
                color={
                  shipmentStatusPalette(activeShipments?.[0]?.Status).background
                }
                spin={isLoadingStore || isLoadingShipments}
                size={selected ? 24 : cluster ? 16 : 16}
              />
            }
            description={
              !selected && cluster ? (
                <div style={{ paddingTop: 4, color: palettes.body.small }}>
                  {t("map.marker.unitCount", { count: cluster })}
                </div>
              ) : null
            }
            selected={selected}
            cluster={cluster}
          />
        );
      }}
      background={(width, height) => (
        <rect width={width} height={height} fill={palettes.body.background} />
      )}
    />
  );
};
