import { CSSProperties, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { v4 } from "uuid";

import { SmallText, UnstyledList } from "@design/helpers";
import DS from "@design/system";
import {
  GroupsWithCountAndStores,
  useGroupsWithStoreCount,
  useSearch,
  useStores,
} from "@state/hooks";
import { useLocalStorage } from "src/util/useLocalStorage";

import GroupHeader from "./GroupHeader";
import HighlightChars from "./HighlightChars";
import Icon from "./Icon";
import Linkable from "./Linkable";
import ListItem from "./ListItem";
import Scroller from "./Scroller";
import SearchBar from "./SearchBar";
import SecondaryButton from "./SecondaryButton";
import SidePanelFooter from "./SidePanelFooter";

export interface SearchResult {
  id: string;
  timestamp: number;
  name: string;
  description: string;
  type: "group" | "store" | "user";
  resultId: string;
}

interface Props {
  navigateToFoundItem: (results: SearchResult) => void;
  searchView: boolean;
  setSearchView: React.Dispatch<React.SetStateAction<boolean>>;
  style: CSSProperties | undefined;
}

const getTypeLabel = (type: "group" | "store" | "user") => {
  switch (type) {
    case "group":
      return "Group";
    case "store":
      return "Store";
    case "user":
      return "Person";
  }
};

export const SearchView = ({
  navigateToFoundItem,
  searchView,
  setSearchView,
}: Props) => {
  const { t } = useTranslation();
  const { palettes } = useTheme();

  const { data: stores } = useStores();
  const { data: groupsWithCount } = useGroupsWithStoreCount();
  const [savedSearchResults, setSavedSearchResults] = useLocalStorage<
    SearchResult[]
  >("recentSearchResults", []);

  const { results, q, setList, search } = useSearch(
    [] as SearchResult[],
    (result) => result.name,
  );

  const handleSearch = useCallback((q: string) => search(q), [search]);
  const handleSearchFocus = useCallback(
    () => setSearchView(true),
    [setSearchView],
  );
  const handleCancelClick = useCallback(
    () => setSearchView(false),
    [setSearchView],
  );

  const showSearchResultInner = useCallback(
    (result: SearchResult) => {
      navigateToFoundItem(result);

      setSearchView(false);

      setSavedSearchResults((previousSavedSearchResults) =>
        saveSearchResults(previousSavedSearchResults, result),
      );
    },
    [navigateToFoundItem, setSavedSearchResults, setSearchView],
  );

  const storeResults = useCallback(
    (
      stores: Api.Store[] | undefined,
      groupsWithCount: GroupsWithCountAndStores | undefined,
      setList: React.Dispatch<React.SetStateAction<SearchResult[] | undefined>>,
    ) => {
      if (!stores || !groupsWithCount) {
        setList([]);
        return;
      }

      const storeResults: SearchResult[] = stores.map((store) => ({
        id: v4(),
        timestamp: 0,
        name: store.Name,
        description: store.LocationAddress.AsOneLine,
        type: "store",
        resultId: store.StoreId,
      }));

      const groupResults: SearchResult[] =
        groupsWithCount &&
        groupsWithCount
          .filter((g) => g.Type === "Franchise" || g.Type === "Adhoc")
          .map((group) => ({
            id: v4(),
            timestamp: 0,
            name: group.Name,
            description: `${group.storeCount} ${t("term.store", {
              count: group.storeCount,
            })}`,
            type: "group",
            resultId: group.GroupId,
          }));

      setList(storeResults.concat(groupResults));
    },
    [t],
  );

  useEffect(() => {
    storeResults(stores, groupsWithCount, setList);
  }, [groupsWithCount, setList, storeResults, stores, t]);

  return (
    <>
      <div
        style={{
          padding: DS.margins.regular,
          display: "grid",
          gap: DS.margins.micro,
          gridAutoFlow: "column",
        }}
      >
        <SearchBar
          placeholder={`Search for groups or ${t(
            "term.store_other",
          ).toLowerCase()}`}
          value={q}
          variant="sidepanel"
          onSearch={handleSearch}
          onFocus={handleSearchFocus}
        />
        {searchView && (
          <SecondaryButton onClick={handleCancelClick}>Cancel</SecondaryButton>
        )}
      </div>
      {searchView && (
        <>
          <Scroller>
            <div
              style={{
                padding: DS.margins.regularCss("rl"),
              }}
            >
              {q ? (
                <UnstyledList>
                  {results &&
                    results.map(({ item: result, positions }) => (
                      <li key={result.id}>
                        <div
                          style={{
                            marginLeft: -DS.margins.microN,
                            marginRight: -DS.margins.microN,
                          }}
                        >
                          <Linkable
                            onClick={() => showSearchResultInner(result)}
                          >
                            <ListItem
                              title={
                                <HighlightChars
                                  str={result.name}
                                  indices={positions}
                                />
                              }
                              description={result.description}
                            />
                          </Linkable>
                        </div>
                      </li>
                    ))}
                </UnstyledList>
              ) : (
                <>
                  {savedSearchResults.length ? (
                    <>
                      <GroupHeader>Recent Search Results</GroupHeader>
                      <UnstyledList style={{ gap: DS.margins.nano }}>
                        {savedSearchResults.map((search) => (
                          <li
                            key={search.id}
                            style={{
                              paddingBottom: DS.margins.nano,
                              borderBottom: "solid 1px",
                              borderBottomColor: palettes.body.border,
                            }}
                          >
                            <div
                              style={{
                                marginLeft: -DS.margins.microN,
                                marginRight: -DS.margins.microN,
                              }}
                            >
                              <Linkable
                                onClick={() => showSearchResultInner(search)}
                              >
                                <div
                                  style={{
                                    padding: DS.margins.micro,
                                    display: "grid",
                                    gap: 8,
                                    gridAutoFlow: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                >
                                  <Icon
                                    name="search"
                                    color={palettes.body.small}
                                  />

                                  <span
                                    style={{
                                      fontWeight: 600,
                                    }}
                                  >
                                    {search.name}
                                  </span>
                                  <span style={{ color: palettes.body.small }}>
                                    &bull;
                                  </span>
                                  <span style={{ color: palettes.body.small }}>
                                    {getTypeLabel(search.type)}
                                  </span>
                                </div>
                              </Linkable>
                            </div>
                          </li>
                        ))}
                      </UnstyledList>
                    </>
                  ) : (
                    <SmallText
                      style={{ margin: DS.margins.micro, textAlign: "center" }}
                    >
                      No recent searches.
                    </SmallText>
                  )}
                </>
              )}
            </div>
          </Scroller>
          <SidePanelFooter />
        </>
      )}
    </>
  );
};

const saveSearchResults = (
  previousSavedSearchResults: SearchResult[],
  result: SearchResult,
) =>
  [
    {
      ...result,
      timestamp: Date.now(),
    },
  ].concat(
    previousSavedSearchResults.filter((r) => r.resultId !== result.resultId),
  );
