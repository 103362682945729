import { DateFormat, dateFormatter } from "@util/dateFormat";

const states = {
  good: 1,
  info: 2,
  warning: 3,
  bad: 4,
};
type UnitStateSummary = keyof typeof states;

interface UnitStatus {
  state: UnitStateSummary;
  title: string;
  description: string;
}

/**
 * Filter helper for getting only the units that are considered "active"
 */
export const activeUnitsOnly = (unit: Api.Unit) => {
  switch (unit.Status) {
    case "RequiresMaintenance":
      return true;
    case "Field":
      return true;

    default:
      return false;
  }
};

const getTitle = (macro: "a" | "b" | "c" | "d" | "e") => {
  switch (macro) {
    default:
    case "a":
      return "Online";
    case "b":
      return "Item count low";
    case "c":
      return "Replenishment overdue";
    case "d":
      return "Item depleted";
    case "e":
      return "Requires Maintenance and Offline";
  }
};

const getDescription = (macro: "a" | "b" | "c" | "d" | "e" | "f" | "g") => {
  switch (macro) {
    default:
    case "a":
      return "This first aid cabinet is online and fully stocked. No further action required.";
    case "b":
      return "One or more items have dropped below their threshold.";
    case "c":
      return "One or more items have been depleted.";
    case "d":
      return "New stock will be dispatched to you.";
    case "e":
      return "New stock has been dispatched and will be with you shortly.";
    case "f":
      return "New stock has been delivered, but not yet placed into the kit. Please restock as soon as possible.";
    case "g":
      return "This first aid cabinet is offline and requires maintenance";
  }
};

export const getLastHeartbeat = (unit: Api.Unit) => {
  return dateFormatter(
    { start: new Date(), end: new Date(unit.LastHeartbeat) },
    DateFormat.distanced,
  );
};

export const getStatus = (unit: Api.Unit): UnitStatus => {
  if (unit.Status !== "Field") {
    switch (unit.Status) {
      case "Testing":
      case "Boxed":
        return {
          state: "info",
          title: "Currently with QA",
          description:
            "This first aid cabinet is in the quality control phase and will delivered to you shortly.",
        };
      case "Transit":
        return {
          state: "info",
          title: "In transit",
          description:
            "This first aid cabinet is in transit and will be with you shortly.",
        };

      case "Decommissioned":
        return {
          state: "info",
          title: "Decommissioned",
          description:
            "This first aid cabinet has been decomissioned as is no longer serviced.",
        };

      case "RequiresMaintenance":
        return {
          state: "info",
          title: getTitle("e"),
          description: getDescription("g"),
        };

      // Should not hit here.
      default:
        return {
          state: "bad",
          title: "…",
          description: "…",
        };
    }
  }

  if (!unit.IsActive)
    return {
      state: "bad",
      title: unit.LastHeartbeat
        ? `Offline for ${getLastHeartbeat(unit)}`
        : "Offline",
      description: "This first aid cabinet is offline.",
    };

  if (unit.ReplenishmentStatus !== "") {
    switch (unit.ReplenishmentStatus) {
      case "Required (Critical)":
      case "Deferred (Critical)":
      case "Deferment Ended (Critical)":
      case "Signed-Off (Critical)":
      case "Signed-Off, Pack-List Sent (Critical)":
      case "Warehouse Packing (Critical)":
        return {
          state: "good",
          title: getTitle("d"),
          description: getDescription("c") + " " + getDescription("d"),
        };

      case "Sent In-Transit (Critical)":
        return {
          state: "good",
          title: getTitle("d"),
          description: getDescription("c") + " " + getDescription("e"),
        };

      case "Delivered (Critical)":
      case "Unpacking (Critical)":
        return {
          state: "warning",
          title: getTitle("d"),
          description: getDescription("c") + " " + getDescription("f"),
        };
      case "Overdue Unpacking (Critical)":
      case "Overdue Unpacking  (Delivered)":
      case "Overdue Unpacking (Critical) (Delivered)":
        return {
          state: "bad",
          title: getTitle("c"),
          description: getDescription("c") + " " + getDescription("f"),
        };

      case "Required ":
      case "Deferred ":
      case "Deferment Ended ":
      case "Signed-Off ":
      case "Signed-Off, Pack-List Sent ":
      case "Warehouse Packing ":
        return {
          state: "good",
          title: getTitle("b"),
          description: getDescription("b") + " " + getDescription("d"),
        };

      case "Sent In-Transit ":
        return {
          state: "good",
          title: getTitle("b"),
          description: getDescription("b") + " " + getDescription("e"),
        };

      case "Delivered ":
      case "Unpacking ":
        return {
          state: "warning",
          title: getTitle("b"),
          description: getDescription("b") + " " + getDescription("f"),
        };
      case "Overdue Unpacking ":
        return {
          state: "bad",
          title: getTitle("b"),
          description: getDescription("b") + " " + getDescription("f"),
        };
    }
  }

  return {
    state: "good",
    title: getTitle("a"),
    description: getDescription("a"),
  };
};
