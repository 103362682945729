import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import Donut, { DonutValue } from "@components/Donut";
import { useUnit } from "@state/hooks";

import { Pin, PinContent } from "./Pin";

export const UnitIncidentMarker = ({
  id,
  selected,
  values,
  cluster,
}: {
  id: string;
  selected: boolean;
  values: DonutValue[];
  cluster?: number | null;
}) => {
  const { t } = useTranslation();
  const { palettes } = useTheme();

  const { data: unit } = useUnit(id);

  const incidents = useMemo(
    () => values.reduce((p, v) => p + v.count, 0),
    [values],
  );

  return (
    <Pin
      label={unit?.UnitName}
      selected={selected}
      cluster={cluster}
      content={() => (
        <PinContent
          label={
            <span
              style={{
                color: palettes.body.foreground,
              }}
            >
              {incidents}
            </span>
          }
          description={
            !selected && cluster ? (
              <span
                style={{
                  color: palettes.body.foreground,
                }}
              >
                {t("map.marker.unitCount", { count: cluster })}
              </span>
            ) : null
          }
          selected={selected}
          cluster={cluster}
        />
      )}
      background={(width, height) => (
        <g transform={`translate(0,${selected ? 0 : height - 100})`}>
          <Donut
            radius={cluster ? 25 : selected ? 20 : 0}
            thickness={width / 2 - (cluster ? 25 : selected ? 20 : 0)}
            values={values}
            emptyColor={palettes.states.na.background}
            background={palettes.body.background}
          />
        </g>
      )}
    />
  );
};
