import color from "color";

import DS from "@design/system";
import { Theme } from "@themes/types";

import { defaultIcons } from "../icons";
import Logo from "./Logo";

/**
 *  Default CFA Theme
 */
export const cfa: Theme = {
  id: "cfa",

  meta: {
    themeName: "Default",

    appName: "Clever First Aid",
    description:
      "Clever First Aid is a smart first aid system that helps ensure you are always ready and prepared in the event of an injury at work.",

    Logo,

    font: "Source Sans Pro",
    links: [
      {
        rel: "preconnect",
        href: "https://fonts.googleapis.com",
      },
      {
        rel: "preconnect",
        href: "https://fonts.gstatic.com",
        crossorigin: "",
      },
      {
        rel: "stylesheet",
        href: "https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap",
      },
      {
        rel: "stylesheet",
        href: "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap",
      },
    ],

    copyright: "Clever First Aid",
  },

  links: {
    footer: [
      {
        label: "Terms of Service",
        url: "https://storage.googleapis.com/cfa-production-documents/CFA-TermsOfService.html",
      },
    ],

    support: { label: "Support", url: "/help/index.html" },

    supportEmail: "support@cleverfirstaid.com",

    termsOfService: {
      label: "Terms of Service",
      url: "https://storage.googleapis.com/cfa-development-documents/CFA-TermsOfService.html",
    },
  },

  map: {
    initial: [172.0, -43.0, 5],
  },

  features: { smsNotifications: true, showBilling: true },

  icons: { ...defaultIcons },

  palettes: {
    signature: DS.paletteExt.realNiceGreen.x500,
    secondary: DS.paletteExt.websiteBlue.x500,

    header: {
      foreground: DS.paletteExt.silver.x100,
      background: DS.paletteExt.silver.x700,
    },

    body: {
      foreground: DS.paletteExt.silver.x800,
      background: DS.paletteExt.silver.x100,
      accent: DS.paletteExt.websiteBlue.x500,

      dim: DS.paletteExt.silver.x200,

      border: DS.paletteExt.silver.x300,

      title: DS.paletteExt.realNiceGreen.x600,
      small: DS.paletteExt.silver.x500,
    },

    well: {
      foreground: DS.paletteExt.silver.x600,
      background: DS.paletteExt.silver.x200,

      dim: DS.paletteExt.silver.x300,

      title: DS.paletteExt.silver.x700,
      small: DS.paletteExt.silver.x500,
    },

    itemSelected: {
      background: DS.paletteExt.websiteBlue.x200,

      light: DS.paletteExt.silver.x200,
      dim: color(DS.paletteExt.silver.x100)
        .mix(color(DS.paletteExt.websiteBlue.x300), 0.5)
        .string(),

      border: DS.paletteExt.websiteBlue.x300,
    },

    tooltip: {
      foreground: DS.paletteExt.silver.x100,
      background: DS.paletteExt.silver.x800,

      small: DS.paletteExt.silver.x500,
    },

    form: {
      foreground: DS.paletteExt.silver.x800,
      background: DS.paletteExt.silver.x100,
      accent: DS.paletteExt.websiteBlue.x500,

      light: DS.paletteExt.silver.x200,
      dim: DS.paletteExt.silver.x300,

      border: DS.paletteExt.silver.x400,

      title: DS.paletteExt.silver.x500,
      small: DS.paletteExt.silver.x500,
    },

    buttons: {
      action: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.realNiceGreen.x500,
        dim: DS.paletteExt.realNiceGreen.x600,

        border: "transparent",
      },
      progressive: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.websiteBlue.x500,
        dim: DS.paletteExt.websiteBlue.x600,

        border: "transparent",
      },
      regressive: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.websiteBlue.x500,
        dim: DS.paletteExt.websiteBlue.x600,

        border: "transparent",
      },
      destructive: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.realNiceRed.x500,
        dim: DS.paletteExt.realNiceRed.x600,

        border: "transparent",
      },
      neutral: {
        foreground: DS.paletteExt.silver.x600,
        background: DS.paletteExt.silver.x100,
        dim: DS.paletteExt.silver.x300,

        border: DS.paletteExt.silver.x400,
      },
      neutralSelected: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.websiteBlue.x600,
        dim: DS.paletteExt.websiteBlue.x700,

        border: DS.paletteExt.websiteBlue.x600,
      },
    },

    messages: {
      success: {
        foreground: DS.paletteExt.realNiceGreen.x700,
        background: DS.paletteExt.realNiceGreen.x100,
      },
      info: {
        foreground: DS.paletteExt.websiteBlue.x700,
        background: DS.paletteExt.websiteBlue.x200,
      },
      notice: {
        foreground: DS.paletteExt.okayOrange.x700,
        background: DS.paletteExt.okayOrange.x100,
      },
      error: {
        foreground: DS.paletteExt.realNiceRed.x600,
        background: DS.paletteExt.realNiceRed.x100,
      },
    },

    states: {
      na: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.silver.x500,
      },
      good: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.realNiceGreen.x500,
      },
      neutral: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.websiteBlue.x500,
      },
      warning: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.okayOrange.x500,
      },
      bad: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.realNiceRed.x500,
      },
    },

    severity: {
      low: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.websiteBlue.x500,
      },
      medium: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.okayOrange.x500,
      },
      high: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.realNiceRed.x500,
      },
    },

    roles: {
      Admin: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.realNiceGreen.x500,
      },
      Consultant: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.websiteBlue.x500,
      },
      Owner: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.silver.x500,
      },
      Supervisor: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.realNiceRed.x500,
      },
      "Local Safety Officer": {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.okayOrange.x500,
      },
    },

    wizardIcon: {
      primary: DS.paletteExt.websiteBlue.x700,
      secondary: DS.paletteExt.websiteBlue.x400,
      fill: DS.paletteExt.websiteBlue.x100,
      background: DS.paletteExt.websiteBlue.x300,
    },
  },
};

/**
 *  CFA Dark
 *
 *  Same as standard CFA theme, but with a dark palette.
 */
export const cfaDark: Theme = {
  ...cfa,

  id: "cfaDark",

  meta: {
    ...cfa.meta,
    themeName: "Default Dark",
  },

  palettes: {
    signature: DS.paletteExt.realNiceGreen.x500,
    secondary: DS.paletteExt.websiteBlue.x500,

    header: {
      foreground: DS.paletteExt.silver.x100,
      background: DS.paletteExt.silver.x700,
    },

    body: {
      foreground: DS.paletteExt.silver.x200,
      background: DS.paletteExt.silver.x700,
      accent: DS.paletteExt.websiteBlue.x500,

      dim: DS.paletteExt.silver.x800,

      border: color(DS.paletteExt.silver.x600)
        .mix(color(DS.paletteExt.silver.x700), 0.75)
        .string(),

      title: DS.paletteExt.realNiceGreen.x600,
      small: DS.paletteExt.silver.x500,
    },

    well: {
      foreground: DS.paletteExt.silver.x500,
      background: DS.paletteExt.silver.x800,

      dim: color(DS.paletteExt.silver.x800).darken(0.15).string(),

      title: DS.paletteExt.silver.x300,
      small: DS.paletteExt.silver.x600,
    },

    itemSelected: {
      background: DS.paletteExt.websiteBlue.x800,

      light: DS.paletteExt.silver.x800,
      dim: color(DS.paletteExt.silver.x900)
        .mix(color(DS.paletteExt.websiteBlue.x800), 0.75)
        .string(),

      border: DS.paletteExt.websiteBlue.x900,
    },

    tooltip: {
      foreground: DS.paletteExt.silver.x100,
      background: DS.paletteExt.silver.x800,

      small: DS.paletteExt.silver.x500,
    },

    form: {
      foreground: DS.paletteExt.silver.x100,
      background: DS.paletteExt.silver.x800,
      accent: DS.paletteExt.websiteBlue.x600,

      light: DS.paletteExt.silver.x700,
      dim: DS.paletteExt.silver.x600,

      border: color(DS.paletteExt.silver.x600)
        .mix(color(DS.paletteExt.silver.x700), 0.75)
        .string(),

      title: DS.paletteExt.silver.x500,
      small: DS.paletteExt.silver.x500,
    },

    buttons: {
      action: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.realNiceGreen.x600,
        dim: DS.paletteExt.realNiceGreen.x700,

        border: "transparent",
      },
      progressive: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.websiteBlue.x600,
        dim: DS.paletteExt.websiteBlue.x700,

        border: "transparent",
      },
      regressive: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.websiteBlue.x600,
        dim: DS.paletteExt.websiteBlue.x700,

        border: "transparent",
      },
      destructive: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.realNiceRed.x600,
        dim: DS.paletteExt.realNiceRed.x700,

        border: "transparent",
      },
      neutral: {
        foreground: DS.paletteExt.silver.x300,
        background: DS.paletteExt.silver.x600,
        dim: DS.paletteExt.silver.x700,

        border: DS.paletteExt.silver.x600,
      },
      neutralSelected: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.websiteBlue.x700,
        dim: DS.paletteExt.websiteBlue.x800,

        border: DS.paletteExt.websiteBlue.x700,
      },
    },

    messages: {
      success: {
        foreground: DS.paletteExt.realNiceGreen.x100,
        background: DS.paletteExt.realNiceGreen.x700,
      },
      info: {
        foreground: DS.paletteExt.websiteBlue.x100,
        background: DS.paletteExt.websiteBlue.x700,
      },
      notice: {
        foreground: DS.paletteExt.okayOrange.x100,
        background: DS.paletteExt.okayOrange.x700,
      },
      error: {
        foreground: DS.paletteExt.realNiceRed.x100,
        background: DS.paletteExt.realNiceRed.x700,
      },
    },

    states: {
      na: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.silver.x500,
      },
      good: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.realNiceGreen.x500,
      },
      neutral: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.websiteBlue.x500,
      },
      warning: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.okayOrange.x500,
      },
      bad: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.realNiceRed.x500,
      },
    },

    severity: {
      low: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.websiteBlue.x500,
      },
      medium: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.okayOrange.x500,
      },
      high: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.realNiceRed.x500,
      },
    },

    roles: {
      Admin: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.realNiceGreen.x600,
      },
      Consultant: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.websiteBlue.x600,
      },
      Owner: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.silver.x600,
      },
      Supervisor: {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.realNiceRed.x600,
      },
      "Local Safety Officer": {
        foreground: DS.paletteExt.silver.x100,
        background: DS.paletteExt.okayOrange.x600,
      },
    },

    wizardIcon: {
      primary: DS.paletteExt.websiteBlue.x700,
      secondary: DS.paletteExt.websiteBlue.x400,
      fill: DS.paletteExt.websiteBlue.x100,
      background: DS.paletteExt.websiteBlue.x300,
    },
  },
};
