import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import Avatar, { AvatarPlaceholder } from "@components/Avatar";
import Button from "@components/Button";
import DaysGoneBanner from "@components/DaysGoneBanner";
import GroupHeader from "@components/GroupHeader";
import GroupedList from "@components/GroupedList";
import Icon from "@components/Icon";
import IncidentsRow from "@components/IncidentsRow";
import Message from "@components/Message";
import ReadinessBanner from "@components/ReadinessBanner";
import Scroller from "@components/Scroller";
import ShipmentCard from "@components/ShipmentCard";
import SidePanelContainer from "@components/SidePanelContainer";
import { SidePanelContentContainer } from "@components/SidePanelContentContainer";
import SidePanelFooter from "@components/SidePanelFooter";
import SidePanelHeader from "@components/SidePanelHeader";
import SidePanelHeaderPlaceholder from "@components/SidePanelHeaderPlaceholder";
import { SmallText, UnstyledList } from "@design/helpers";
import DS from "@design/system";
import {
  useEscalations,
  useShipments,
  useStore,
  useUsersInStore,
} from "@state/hooks";
import { useMapState } from "@util/MapState";
import { useSidePanels } from "@util/SidePanels";
import {
  getEscalationIcon,
  openAndNotPausedEscalationsOnly,
  EscalationTitle,
  EscalationDescription,
} from "@util/escalations";
import { activeShipmentsOnly } from "@util/shipments";
import { useThemeHelper } from "@util/useThemeHelper";

import EscalationSidePanel from "./EscalationSidePanel";
import IncidentSidePanel from "./IncidentSidePanel";

export const AvatarCount = styled.div`
  width: 43px;
  height: 43px;

  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.palettes.well.small};

  background: ${({ theme }) => theme.palettes.well.background};
  border-radius: ${DS.radii.item};

  display: grid;
  align-content: center;
  justify-content: center;
`;
const AVATARS_DISPLAYED = 6;

const StoreSidePanel = ({
  storeId,
  closeable = true,
}: {
  storeId: string;
  closeable?: boolean;
}) => {
  const history = useHistory();
  const { escalationsPalette } = useThemeHelper();

  const { push, pop } = useSidePanels();
  const { setStoreId, flyTo, setMarkerViewState } = useMapState();

  const { data: store } = useStore(storeId);
  const users = useUsersInStore(storeId);
  const escalations = useEscalations({ storeId });
  const shipments = useShipments({ storeId, numberDays: 90 });
  const { t } = useTranslation();
  const activeShipments = useMemo(
    () => shipments?.data?.filter(activeShipmentsOnly),
    [shipments.data],
  );

  const [undisplayedUsers, setUndisplayedUsers] = useState(0);

  const usersToDisplay = useMemo(() => {
    const filteredUser =
      users.data?.filter((user) =>
        user.Groups.find(
          (group) => group.GroupId === store?.SingleStoreGroupID,
        ),
      ) ?? [];

    if (filteredUser.length > AVATARS_DISPLAYED) {
      setUndisplayedUsers(filteredUser.length - AVATARS_DISPLAYED + 1);
      return filteredUser
        .sort((a, b) => a.LastName.localeCompare(b.LastName))
        .slice(0, AVATARS_DISPLAYED - 1);
    } else {
      return filteredUser
        .sort((a, b) => a.LastName.localeCompare(b.LastName))
        .slice(0, AVATARS_DISPLAYED);
    }
  }, [store, users.data]);

  const handleIncidentClick = useCallback(
    (incident: Api.Incident) => {
      push({
        id: `incident-${incident.incidentId}`,
        content: <IncidentSidePanel incident={incident} />,
      });
    },
    [push],
  );

  const handleEscalationClick = useCallback(
    (escalation: Api.Escalation) => {
      push({
        id: `escalation-${escalation.UnitReminderId}`,
        content: <EscalationSidePanel escalation={escalation} />,
      });
    },
    [push],
  );

  const handleCloseClick = useCallback(() => {
    setStoreId(null);
    pop();
  }, [pop, setStoreId]);

  const handleShowOnMapClick = useCallback(() => {
    if (store) {
      setMarkerViewState({ type: "store" });

      flyTo([store.LocationAddress.Longitude, store.LocationAddress.Latitude]);
    }
  }, [flyTo, store, setMarkerViewState]);

  const handleViewAllUsersClick = useCallback(() => {
    history.push(`/store/${storeId}/people`);
  }, [history, storeId]);

  const handleViewStoreClick = useCallback(() => {
    history.push(`/store/${storeId}`);
  }, [history, storeId]);

  const handleViewAllShipmentsClick = useCallback(() => {
    history.push(`/store/${storeId}/shipments`);
  }, [history, storeId]);

  const handleViewAllIncidentsClick = useCallback(() => {
    history.push(`/store/${storeId}/incidents`);
  }, [history, storeId]);

  return (
    <SidePanelContainer aria-label="Store">
      <div>
        {!store ? (
          <SidePanelHeaderPlaceholder />
        ) : (
          <SidePanelHeader
            title={store.Name}
            subtitle={store.LocationAddress.AsOneLine}
            closeTitle="Close store view"
            onClose={closeable ? handleCloseClick : undefined}
          />
        )}
        <Button
          stretch
          fit="compact"
          shape="square"
          buttonType="progressive"
          onClick={handleShowOnMapClick}
        >
          Show On Map <Icon name="arrow-alt-right" />
        </Button>
        <div
          style={{
            margin: DS.margins.regular,
            display: "grid",
            gap: DS.margins.micro,
            gridTemplateColumns: "1fr auto",
          }}
        >
          <ReadinessBanner storeId={storeId} />
          <DaysGoneBanner storeId={storeId} />
        </div>
      </div>

      <Scroller>
        <SidePanelContentContainer
          contents={[
            <>
              <GroupHeader
                controls={[
                  {
                    title: "View all people for " + (store?.Name ?? "…"),
                    icon: "arrow-up-right-from-square",
                    onClick: handleViewAllUsersClick,
                  },
                ]}
              >
                People in {t("term.store_one").toLowerCase()}
              </GroupHeader>
              {users.isLoading ? (
                <UnstyledList horizontal gap={6}>
                  <AvatarPlaceholder size={43} />
                  <AvatarPlaceholder size={43} />
                  <AvatarPlaceholder size={43} />
                  <AvatarPlaceholder size={43} />
                  <AvatarPlaceholder size={43} />
                  <AvatarPlaceholder size={43} />
                </UnstyledList>
              ) : users.isError ? (
                <SmallText style={{ textAlign: "center" }}>
                  <Icon name="exclamation-alt-circle" /> Error loading people
                </SmallText>
              ) : !users.data ? (
                <SmallText style={{ textAlign: "center" }}>No people</SmallText>
              ) : (
                <UnstyledList horizontal gap={6}>
                  {usersToDisplay.map((user) => (
                    <li key={user.UserId}>
                      <Avatar
                        userId={user.UserId}
                        imageUrl={user.PhotoUrl}
                        size={43}
                      />
                    </li>
                  ))}
                  {undisplayedUsers > 0 && (
                    <li>
                      <AvatarCount>+{undisplayedUsers}</AvatarCount>
                    </li>
                  )}
                </UnstyledList>
              )}
            </>,
            <>
              <GroupHeader
                controls={[
                  {
                    title:
                      `View all ${t(
                        "term.incident_other",
                      ).toLowerCase()} for ` + (store?.Name ?? "…"),
                    icon: "arrow-up-right-from-square",
                    onClick: handleViewAllIncidentsClick,
                  },
                ]}
              >
                Recent {t("severity.high")}/{t("severity.medium")}{" "}
                {t("term.incident_other")}
              </GroupHeader>
              <Scroller gutter={DS.margins.regularN}>
                <IncidentsRow
                  storeId={storeId}
                  onIncidentClick={handleIncidentClick}
                />
              </Scroller>
            </>,
            <>
              <GroupedList
                separator
                tight
                title={t("common.actionsToDo")}
                emptyLabel={
                  <Message type="success">
                    All tasks <strong>complete</strong>, no action required.
                  </Message>
                }
                data={escalations.data?.filter(openAndNotPausedEscalationsOnly)}
                isLoading={escalations.isLoading}
                isError={escalations.isError}
                errorLabel="Error loading actions"
                listItemKey={(escalation) => escalation.UnitReminderId}
                listItemXpathId={(escalation) =>
                  escalation.ControllerSerialNumber
                }
                listItemImage={(escalation) => (
                  <Icon
                    name={getEscalationIcon(escalation.ReminderType)}
                    color={escalationsPalette(escalation).background}
                  />
                )}
                listItemTitle={(escalation) => (
                  <EscalationTitle escalation={escalation} />
                )}
                listItemDescription={(escalation) => (
                  <EscalationDescription escalation={escalation} />
                )}
                onListItemClick={handleEscalationClick}
              />
            </>,
            <>
              <GroupHeader
                controls={[
                  {
                    title: "View all shipments for " + (store?.Name ?? "…"),
                    icon: "arrow-up-right-from-square",
                    onClick: handleViewAllShipmentsClick,
                  },
                ]}
              >
                Shipments
              </GroupHeader>
              {activeShipments && activeShipments.length > 0 ? (
                <ShipmentCard shipments={activeShipments} size="full" />
              ) : (
                <Message type="success">No active shipments.</Message>
              )}
            </>,
          ]}
        />
      </Scroller>

      <SidePanelFooter>
        <Button buttonType="progressive" stretch onClick={handleViewStoreClick}>
          Open {t("term.store_one").toLowerCase()} details{" "}
          <Icon name="arrow-up-right-from-square" />
        </Button>
      </SidePanelFooter>
    </SidePanelContainer>
  );
};

export default StoreSidePanel;
