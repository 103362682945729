import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import useResizeObserver from "use-resize-observer";

import OverviewSidePanel from "@sidepanels/OverviewSidePanel";
import StoreSidePanel from "@sidepanels/StoreSidePanel";
import UnitSidePanel from "@sidepanels/UnitSidePanel";
import {
  useIncidents,
  usePageTitle,
  useStores,
  useStoresInGroup,
  useUnits,
} from "@state/hooks";

import { useMapState } from "../util/MapState";
import { useSidePanels } from "../util/SidePanels";
import Map from "./Map";
import SidePanel from "./SidePanel";

const PanelContainer = styled(motion.div)`
  z-index: 1;
  position: absolute;
  width: 320px;
  top: 16px;
  bottom: 16px;
  left: 16px;
`;

const MapView = () => {
  const match = useRouteMatch(["/group", "/store"]);
  const {
    groupId,
    storeId,
    setStoreId,
    controllerSerialNumber,
    setControllerSerialNumber: setUnitId,
  } = useMapState();

  const { data: allStores } = useStores();
  const { data: storesInGroup } = useStoresInGroup(groupId ?? undefined, true);
  const { data: incidents } = useIncidents({ numberDays: 14 });
  const { data: units } = useUnits({
    groupId: groupId ?? undefined,
  });

  usePageTitle("Overview");

  const singleStore = useMemo(
    () => (allStores && allStores.length === 1 ? allStores[0] : null),
    [allStores],
  );

  const { push, swap, pop } = useSidePanels();

  const handleStoreSelected = useCallback(
    (store: Api.Store | null) => {
      if (singleStore) return;

      setStoreId((previousStoreId) => {
        if (previousStoreId && !store) {
          pop(`store-${previousStoreId}`);
        }

        if (!previousStoreId && store) {
          push({
            id: `store-${store.StoreId}`,
            content: <StoreSidePanel storeId={store.StoreId} />,
          });
        }

        if (previousStoreId && store) {
          swap(`store-${previousStoreId}`, {
            id: `store-${store.StoreId}`,
            content: <StoreSidePanel storeId={store.StoreId} />,
          });
        }

        return store?.StoreId || null;
      });
    },
    [singleStore, setStoreId, pop, push, swap],
  );

  const handleUnitSelected = useCallback(
    (unit: Api.Unit | null) => {
      setUnitId((previousUnitId) => {
        if (previousUnitId && !unit) {
          pop(`unit-${previousUnitId}`);
        }

        if (!previousUnitId && unit) {
          push({
            id: `unit-${unit.ControllerSerialNumber}`,
            content: (
              <UnitSidePanel
                controllerSerialNumber={unit.ControllerSerialNumber}
              />
            ),
          });
        }

        if (
          previousUnitId &&
          unit &&
          previousUnitId !== unit.ControllerSerialNumber
        ) {
          swap(`unit-${previousUnitId}`, {
            id: `unit-${unit.ControllerSerialNumber}`,
            content: (
              <UnitSidePanel
                controllerSerialNumber={unit.ControllerSerialNumber}
              />
            ),
          });
        }

        return unit?.ControllerSerialNumber || null;
      });
    },
    [pop, push, setUnitId, swap],
  );

  useEffect(() => {
    if (singleStore) {
      swap("overview", {
        id: "overview",
        content: (
          <StoreSidePanel storeId={singleStore.StoreId} closeable={false} />
        ),
      });

      setStoreId(singleStore.StoreId);
    } else {
      swap("overview", {
        id: "overview",
        content: <OverviewSidePanel />,
      });
    }
  }, [setStoreId, singleStore, swap]);

  const { ref, width, height } = useResizeObserver();

  return (
    <div style={{ position: "relative" }} ref={ref}>
      {import.meta.env.VITE_APP_ENV_NAME !== "e2e" &&
        (width ?? 0) > 600 &&
        (height ?? 0) > 500 && (
          <Map
            selectedStoreId={storeId ?? undefined}
            selectedControllerSerialNumber={controllerSerialNumber ?? undefined}
            onStoreSelected={handleStoreSelected}
            onUnitSelected={handleUnitSelected}
            stores={
              storesInGroup && storesInGroup.length ? storesInGroup : allStores
            }
            units={units}
            incidents={incidents}
          />
        )}

      <AnimatePresence initial={false}>
        <PanelContainer
          animate={{
            opacity: match ? 0 : 1,
            transform: match ? "scale(0.9)" : "scale(1)",
          }}
          transition={{ duration: 0.2, ease: "easeOut" }}
        >
          <SidePanel />
        </PanelContainer>
      </AnimatePresence>
    </div>
  );
};

export default MapView;
