import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch, useLocation } from "react-router-dom";

import LogInPage from "@auth/LogInPage";
import { SessionState, useAuth } from "@auth/auth";
import useRedirectUrl from "@auth/useRedirectUrl";
import en from "@lang/en";
import mi from "@lang/mi";
import pt from "@lang/pt";
import { useAnayltics } from "@util/analytics";
import useCrashReporter from "@util/useCrashReporter";

import AuthenticatedPage from "./AuthenticatedPage";

const App = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const match = useRouteMatch(["/group", "/store", "/all-stores"]);

  useRedirectUrl();

  const { session } = useAuth();
  const { track } = useAnayltics();
  const { visit } = useCrashReporter();

  useEffect(() => {
    i18n
      .addResourceBundle("en", "translation", en)
      .addResourceBundle("mi", "translation", mi)
      .addResourceBundle("pt", "translation", pt);
  }, [i18n]);

  useEffect(() => {
    track(location.pathname);
    visit(location.pathname);
  }, [location, track, visit]);

  return session.state !== SessionState.Authenticated ? (
    <LogInPage
      loading={
        session.state === SessionState.Unknown ||
        session.state === SessionState.Restoring ||
        session.state === SessionState.Confirming
      }
    />
  ) : (
    <AuthenticatedPage showDetailView={!!match} />
  );
};

export default App;
