import styled, { useTheme } from "styled-components";

import { ModalTitle } from "@design/helpers";
import DS from "@design/system";

import CloseButton from "./CloseButton";
import IconButton from "./IconButton";

const Container = styled.header`
  padding: ${DS.margins.regularCss("tb")};
  margin: ${DS.margins.regularCss("rl")};

  display: grid;
  grid-template-columns: 28px 1fr 28px;
  justify-items: center;
`;

const ModalHeader = ({
  title,
  backLabel,
  onBack,
  onClose,
}: {
  title: string;
  backLabel?: string | null;
  onBack?: () => void;
  onClose?: () => void;
}) => {
  const { palettes } = useTheme();

  return (
    <Container>
      <span style={{ color: palettes.body.small }}>
        {backLabel && (
          <IconButton
            icon="arrow-alt-left"
            iconSize={18}
            fit="compact"
            buttonType="transparent"
            title={backLabel}
            onClick={onBack}
          />
        )}
      </span>

      <ModalTitle>{title}</ModalTitle>

      <span>
        {onClose && <CloseButton title="Close dialog" onClick={onClose} />}
      </span>
    </Container>
  );
};

export default ModalHeader;
