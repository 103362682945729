import { useQueryClient } from "@tanstack/react-query";
import { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import api, { ApiResponseError, AuthState, client, isApiError } from "@api";

import { ls } from "../util/localStorage";

const SESSION_KEY = "session";
const PREVIOUSLY_VISITED_KEY = "previouslyVisited";
const INCIDENT_DAYS_KEY = "incidentDays";
const SHARED_DEVICE_KEY = "sharedDevice";
const SEARCH_KEY = "recentSearchResults";

export enum SessionState {
  /**
   * No valid session state was found in local storage.
   *
   * The user has either come from Portal, or they are not logged in.
   */
  Unknown = "unknown",

  /**
   * Session state was found in local storage.
   *
   * Valid session state was found in local storage, but we make no assumptions about its validity.
   */
  Restoring = "restoring",

  /**
   * Confirming the current session state.
   *
   * The current locally stored state (or lack there of) has been acknowledged. We are now checking the cookie.
   */
  Confirming = "confirming",

  /**
   * Not authenticated.
   *
   * We've checked everything. The user is not authenticated.
   */
  None = "none",

  /**
   * Awaiting MFA
   *
   * The log in attempt was valid, however the user now needs to provide a MFA verification code.
   **/
  AwaitingMfa = "awaiting-mfa",

  /**
   * Authenticated
   *
   * Session state has been saved to localstorage and a cookie is confirmed present.
   */
  Authenticated = "authenticated",
}

export interface Session {
  user?: Api.CurrentUser;

  state: SessionState;
}

export interface AuthInterface {
  session: Session;

  /**
   * @throws Api.Response<unknown, Api.UserValidationError>
   */
  logIn: (
    email: string,
    password: string,
    sharedDevice?: boolean,
  ) => Promise<AuthState>;

  /**
   * @throws Api.Response<unknown, Api.UserValidationError>
   */
  submitMfa: (email: string, code: string) => Promise<void>;

  /**
   * @throws Api.Response<unknown, Api.UserValidationError>
   */
  submitNewPassword: (
    email: string,
    password: string,
    resetKey: string,
  ) => Promise<void>;

  logOut: () => Promise<void>;
}

const clearSessionStorage = () => {
  ls.removeItem(SESSION_KEY);
  ls.removeItem(PREVIOUSLY_VISITED_KEY);
  ls.removeItem(INCIDENT_DAYS_KEY);
  ls.removeItem(SHARED_DEVICE_KEY);
  ls.removeItem(SEARCH_KEY);
};

const initialSession: Session = { state: SessionState.Unknown };

const AuthContext = createContext<AuthInterface>({
  session: initialSession,

  logIn: () => new Promise(() => null),
  submitMfa: () => new Promise(() => null),
  submitNewPassword: () => new Promise(() => null),
  logOut: () => new Promise(() => null),
});

export const useAuth = () => useContext(AuthContext);

type Resolver = {
  resolve: () => void;
  reject: () => void;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();

  const error4xxHandler = useRef<number | null>(null);
  const focusValidating = useRef<"idle" | "validating">("idle");
  const requestQueueInterceptor = useRef<number | null>(null);
  const xsrfResolvers = useRef<Resolver[]>([]);
  const abortController = useRef<AbortController | null>(null);

  const [session, setSession] = useState<Session>(initialSession);

  const queueRequests = useCallback(
    async (
      request: InternalAxiosRequestConfig,
    ): Promise<InternalAxiosRequestConfig> => {
      return new Promise((resolve, reject) => {
        xsrfResolvers.current.push({
          resolve: () => resolve(request),
          reject: () => reject(request),
        });
      });
    },
    [],
  );

  const updateSession = useCallback(
    async (state: SessionState) => {
      if (state === SessionState.Authenticated) {
        const user = await api.users.current();
        queryClient.setQueryData(["currentUser"], user);

        const terminology = await api.terminology.get();
        queryClient.setQueryData(["terminology"], terminology);

        setSession({ state, user });
        return;
      }

      if (state === SessionState.Restoring || state === SessionState.None) {
        queryClient.clear();
        clearSessionStorage();
      }

      setSession({ state });
    },
    [queryClient],
  );

  const detachRequestQueue = useCallback(() => {
    if (!requestQueueInterceptor.current) return;

    client.interceptors.request.eject(requestQueueInterceptor.current);
    requestQueueInterceptor.current = null;
  }, []);

  const attachRequestQueue = useCallback(() => {
    if (requestQueueInterceptor.current) detachRequestQueue();

    requestQueueInterceptor.current =
      client.interceptors.request.use(queueRequests);
  }, [detachRequestQueue, queueRequests]);

  const refreshXsrfToken = useCallback(() => {
    const refresher = new Promise<void>((resolve, reject) => {
      api.auth
        .xsrfToken()
        .then((result) => {
          api.setXsrf(result);

          detachRequestQueue();
          xsrfResolvers.current.forEach((r) => r.resolve());

          resolve();
        })
        .catch(() => {
          xsrfResolvers.current.forEach((r) => r.reject());
          detachRequestQueue();

          reject();
        });
    });

    // Queue up al further requests for until we have a valid XSRF token.
    attachRequestQueue();

    return refresher;
  }, [attachRequestQueue, detachRequestQueue]);

  const logIn = useCallback(
    async (
      email: string,
      password: string,
      sharedDevice?: boolean,
    ): Promise<AuthState> => {
      clearSessionStorage();

      queryClient.clear();
      await queryClient.cancelQueries();

      if (abortController.current) abortController.current.abort();

      ls.setItem(SHARED_DEVICE_KEY, String(sharedDevice ?? false));

      const { status } = await api.logIn(email, password, sharedDevice);

      if (status === "mfa-email" || status === "mfa-totp") {
        await updateSession(SessionState.AwaitingMfa);

        return status;
      } else {
        await refreshXsrfToken();
        await updateSession(SessionState.Authenticated);

        return "authenticated";
      }
    },
    [queryClient, refreshXsrfToken, updateSession],
  );

  const submitMfa = useCallback(
    async (email: string, code: string) => {
      await api.submitMfa(email, code);

      await refreshXsrfToken();
      await updateSession(SessionState.Authenticated);
    },
    [refreshXsrfToken, updateSession],
  );

  const submitNewPassword = useCallback(
    async (email: string, password: string, resetKey: string) => {
      await api.submitNewPassword(email, password, resetKey);

      await refreshXsrfToken();
      await updateSession(SessionState.Authenticated);
    },
    [refreshXsrfToken, updateSession],
  );

  const logOut = useCallback(async () => {
    await queryClient.cancelQueries();
    await api.logOut();

    await updateSession(SessionState.None);

    api.clearXsrf();
    clearSessionStorage();
  }, [queryClient, updateSession]);

  useEffect(() => {
    if (ls.getItem(SHARED_DEVICE_KEY) === "true") {
      window.onbeforeunload = () => {
        void logOut();
      };
    }
  }, [logOut]);

  const handle4xx = useCallback(
    async (response: AxiosResponse<unknown>) => {
      if (response.status === 401) {
        await updateSession(SessionState.None);
        throw new Error("Unauthorized");
      } else if (response.status === 404) {
        throw new Error("Not found");
      } else if (isApiError(response.data)) {
        const error = new ApiResponseError(response.data);

        if (error.error.isXsrfError()) void refreshXsrfToken();

        throw error;
      }

      return response;
    },
    [refreshXsrfToken, updateSession],
  );

  const handleFocus = useCallback(() => {
    if (focusValidating.current === "validating") return;
    focusValidating.current = "validating";

    if (abortController.current) abortController.current.abort();
    abortController.current = new AbortController();

    const cachedUser = queryClient.getQueryData<Api.CurrentUser>([
      "currentUser",
    ]);

    void api.users
      .current(abortController.current.signal)
      .then(async (currentUser) =>
        cachedUser && cachedUser.userId === currentUser.userId
          ? await updateSession(SessionState.Authenticated)
          : await updateSession(SessionState.Restoring),
      )
      .catch(() => queryClient.clear())
      .finally(() => (focusValidating.current = "idle"));
  }, [queryClient, updateSession]);

  const auth: AuthInterface = useMemo(
    () => ({
      session,
      logIn,
      submitMfa,
      submitNewPassword,
      logOut,
    }),
    [logIn, logOut, session, submitMfa, submitNewPassword],
  );

  // 4xx handler
  useEffect(() => {
    error4xxHandler.current = client.interceptors.response.use(handle4xx);

    return () => {
      error4xxHandler.current &&
        client.interceptors.response.eject(error4xxHandler.current);
      error4xxHandler.current = null;
    };
  }, [handle4xx]);

  // Session restore/check
  useEffect(() => {
    if (
      session.state === SessionState.Unknown ||
      session.state === SessionState.Restoring
    ) {
      setSession({ state: SessionState.Confirming });

      refreshXsrfToken()
        .then(async () => {
          await updateSession(SessionState.Authenticated);
        })
        .catch(async () => {
          await updateSession(SessionState.None);
        });
    }

    return () => {
      if (error4xxHandler.current) {
        detachRequestQueue();
      }
    };
  }, [
    attachRequestQueue,
    detachRequestQueue,
    handle4xx,
    queryClient,
    refreshXsrfToken,
    session,
    updateSession,
  ]);

  // Focus handling
  useEffect(() => {
    if (typeof window !== "undefined" && window.addEventListener) {
      window.addEventListener("visibilitychange", handleFocus, false);
      window.addEventListener("focus", handleFocus, false);
    }

    return () => {
      window.removeEventListener("visibilitychange", handleFocus);
      window.removeEventListener("focus", handleFocus);
    };
  }, [handleFocus]);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
