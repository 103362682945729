import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useUnit } from "@state/hooks";
import { useThemeHelper } from "@util/useThemeHelper";

import { Pin, PinContent } from "./Pin";

export const UnitEscalationMarker = ({
  id,
  selected,
  actions,
  cluster,
}: {
  id: string;
  selected: boolean;
  actions: number;
  cluster?: number | null;
}) => {
  const { t } = useTranslation();
  const { escalationsCountPalette } = useThemeHelper();

  const { data: unit } = useUnit(id);

  const { foreground, background } = useMemo(
    () => escalationsCountPalette(actions),
    [actions, escalationsCountPalette],
  );

  return (
    <Pin
      label={unit?.UnitName}
      selected={selected}
      cluster={cluster}
      content={() => (
        <PinContent
          label={<span style={{ color: foreground }}>{actions}</span>}
          description={
            !selected && cluster ? (
              <span style={{ color: foreground }}>
                {t("map.marker.unitCount", { count: cluster })}
              </span>
            ) : (
              <span style={{ color: foreground }}>Actions</span>
            )
          }
          selected={selected}
          cluster={cluster}
        />
      )}
      background={(width, height) => (
        <rect width={width} height={height} fill={background} />
      )}
    />
  );
};
