import { ThemeIcons } from "@themes/types";

export const defaultIcons: ThemeIcons = {
  unitType: {
    FirstAidStation: "first-aid-inverted",
    AutomatedExternalDefibrillator: "aed-inverted",
    Cube: "question-circle",
  },

  eventCategory: {
    Cuts: "plaster",
    Burns: "temperature",
    Eyes: "eye",
    STF: "clipboard-check",
    Cleaning: "tint",
    Safety: "shield-alt",

    // Unused
    Uncategorized: "question-circle",
    "Box of Items": "question-circle",
    Hardware: "question-circle",
    Tools: "question-circle",
  },

  other: {
    overview: "home",
    device: "mobile-alt",
    incident: "briefcase-medical",
    shipment: "truck",
    person: "user-friends",
    store: "home",
    group: "grid",
    company: "building",
    billing: "money",
    setting: "cogs",
  },
};
