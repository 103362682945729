import { useCallback, useState } from "react";
import { useModal } from "react-modal-hook";
import styled, { useTheme } from "styled-components";

import api from "@api";
import ClickyThingy from "@components/ClickyThing";
import ErrorWell from "@components/ErrorWell";
import Icon from "@components/Icon";
import IconButton from "@components/IconButton";
import LocalisedDate from "@components/LocalisedDate";
import Pill from "@components/Pill";
import {
  SmallText,
  StrongText,
  TitleSmall,
  UnstyledList,
} from "@design/helpers";
import DS from "@design/system";
import BillingContactModal from "@modals/BillingContactModal";
import {
  useHasPermission,
  useInvoices,
  usePageTitle,
  useStore,
} from "@state/hooks";
import ContentLoader, { TextBlock, TextLoader } from "@util/ContentLoader";
import { DateFormat } from "@util/dateFormat";
import useParamsUpper from "@util/useParamsUpper";

const lastDays = (days: number) => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() - days);

  return date;
};

const download = (
  { storeId, invoice }: { storeId: string; invoice: Api.Invoice },
  { onSuccess }: { onSuccess?: () => void } = { onSuccess: () => null },
) => {
  void api.billing.downloadInvoicePdf(storeId, invoice).then(() => {
    onSuccess && onSuccess();
  });
};

const DataGrid = styled.div`
  & > div,
  ul li {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr auto;

    align-items: center;
    justify-items: flex-start;
  }

  ul li:nth-child(odd) {
    background: ${({ theme }) => theme.palettes.body.border};
  }

  & > div > span,
  ul li > span {
    padding: ${DS.margins.nano} ${DS.margins.micro};
  }

  & > div {
    font-weight: 600;
  }
`;

const Billing = () => {
  const { palettes } = useTheme();
  const { storeId } = useParamsUpper<{ storeId: string }>();

  const { hasPermission } = useHasPermission();
  const { data: store } = useStore(storeId);
  const {
    data: billing,
    isLoading,
    isError,
  } = useInvoices(storeId, lastDays(90));

  const [downloading, setDownloading] = useState<{ [key: string]: boolean }>(
    {},
  );

  usePageTitle(`Billing ${store ? " - " + store.Name : ""}`);

  const [showEditBillingContactModal, closeEditBillingContactModal] = useModal(
    () => (
      <BillingContactModal
        storeId={storeId}
        onSave={() => closeEditBillingContactModal()}
        onClose={() => closeEditBillingContactModal()}
      />
    ),
    [storeId],
  );

  const handleInvoiceDownload = useCallback(
    (storeId?: string, invoice?: Api.Invoice) => {
      if (!storeId || !invoice) return;

      setDownloading((prevDownloading) => ({
        ...prevDownloading,
        [invoice.InvoiceID]: true,
      }));

      download(
        { storeId, invoice },
        {
          onSuccess: () => {
            setDownloading((prevDownloading) => {
              const copy = { ...prevDownloading };
              delete copy[invoice.InvoiceID];

              return copy;
            });
          },
        },
      );
    },
    [],
  );

  return (
    <div
      style={{
        height: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 320px",
      }}
    >
      {hasPermission("stores_create_delete_store") && (
        <div
          style={{
            padding: DS.margins.regular,
            display: "grid",
            gap: DS.margins.regular,
            alignContent: "start",
          }}
        >
          <TitleSmall>Billing History</TitleSmall>

          <div style={{ textAlign: "center" }}>
            <SmallText>
              Statement as at{" "}
              <LocalisedDate dateTime={new Date()} format={DateFormat.short} />
            </SmallText>
            <span
              style={{
                display: "inline-block",
                padding: "8px 32px",
                fontSize: 18,
                fontWeight: 600,
                background: palettes.well.background,
                borderRadius: DS.radii.largeItem,
              }}
            >
              {isLoading ? (
                <TextLoader width={50} />
              ) : isError ? (
                <ErrorWell>Unable to load balance</ErrorWell>
              ) : (
                new Intl.NumberFormat("en-NZ", {
                  style: "currency",
                  currency: "NZD",
                }).format(billing?.AmountOwing ?? 0)
              )}
            </span>
            <SmallText>Closing balance</SmallText>
          </div>

          <DataGrid>
            <div>
              <span>Invoice number</span>
              <span>Invoice date</span>
              <span>Due date</span>
              <span style={{ justifySelf: "flex-end" }}>Amount due</span>
              <span></span>
              <span style={{ width: 46 }}></span>
            </div>
            {isLoading ? (
              <UnstyledList>
                <li>
                  <span>
                    <TextLoader width={50} />
                  </span>
                  <span>
                    <TextLoader width={70} />
                  </span>
                  <span>
                    <TextLoader width={70} />
                  </span>
                  <span>
                    <TextLoader width={50} />
                  </span>
                </li>
                <li>
                  <span>
                    <TextLoader width={50} />
                  </span>
                  <span>
                    <TextLoader width={70} />
                  </span>
                  <span>
                    <TextLoader width={70} />
                  </span>
                  <span>
                    <TextLoader width={40} />
                  </span>
                </li>
                <li>
                  <span>
                    <TextLoader width={50} />
                  </span>
                  <span>
                    <TextLoader width={70} />
                  </span>
                  <span>
                    <TextLoader width={70} />
                  </span>
                  <span>
                    <TextLoader width={45} />
                  </span>
                </li>
                <li>
                  <span>
                    <TextLoader width={50} />
                  </span>
                  <span>
                    <TextLoader width={70} />
                  </span>
                  <span>
                    <TextLoader width={70} />
                  </span>
                  <span>
                    <TextLoader width={40} />
                  </span>
                </li>
              </UnstyledList>
            ) : isError ? (
              <div>
                <ErrorWell
                  style={{
                    gridColumnStart: "span 5",
                    textAlign: "center",
                    justifySelf: "stretch",
                  }}
                >
                  <Icon name="exclamation-alt-circle" /> There was a problem
                  loading invoices.
                </ErrorWell>
              </div>
            ) : (
              <UnstyledList>
                {billing?.Invoices.sort(
                  (a, b) =>
                    new Date(b.Date).getTime() - new Date(a.Date).getTime(),
                ).map((invoice) => (
                  <li key={invoice.InvoiceID}>
                    <span>{invoice.InvoiceNumber}</span>
                    <span>
                      <LocalisedDate
                        dateTime={invoice.Date}
                        format={DateFormat.short}
                      />
                    </span>
                    <span>
                      <LocalisedDate
                        dateTime={invoice.DueDate}
                        format={DateFormat.short}
                      />
                    </span>
                    <span style={{ justifySelf: "flex-end" }}>
                      {new Intl.NumberFormat("en-NZ", {
                        style: "currency",
                        currency: invoice.CurrencyCode,
                      }).format(invoice.Total)}
                    </span>
                    <span style={{ justifySelf: "center" }}>
                      <Pill
                        type={invoice.Status === "PAID" ? "action" : "default"}
                      >
                        {invoice.Status === "PAID" ? "Paid" : "Unpaid"}
                      </Pill>
                    </span>
                    <span>
                      <IconButton
                        iconSize={12}
                        icon={
                          downloading[invoice.InvoiceID]
                            ? "spinner"
                            : "cloud-download"
                        }
                        title={`Download invoice ${invoice.InvoiceNumber}`}
                        spin={downloading[invoice.InvoiceID]}
                        disabled={downloading[invoice.InvoiceID]}
                        onClick={() => handleInvoiceDownload(storeId, invoice)}
                      />
                    </span>
                  </li>
                ))}
              </UnstyledList>
            )}
          </DataGrid>
        </div>
      )}

      <div
        style={{
          padding: DS.margins.regular,
          borderLeft: `solid 1px ${palettes.body.border}`,
          display: "grid",
          gap: DS.margins.regular,
          alignContent: "start",
        }}
      >
        <TitleSmall>Billing Contact</TitleSmall>

        {!store ? (
          <div>
            <ContentLoader
              title="Loading store information…"
              width={160}
              height={120}
            >
              <TextBlock x={0} y={5} width={120} fontSize={12} />
              <TextBlock x={0} y={25} width={160} fontSize={12} />
              <TextBlock x={0} y={45} width={100} fontSize={12} />
            </ContentLoader>
          </div>
        ) : (
          <ClickyThingy
            title="Edit billing contact"
            onClick={
              hasPermission("stores_create_delete_store")
                ? () => showEditBillingContactModal()
                : undefined
            }
          >
            <p style={{ margin: 0 }}>
              <StrongText as="span">{store.GetBillingOrDefaultName}</StrongText>
              <br />
              <span>
                {store.IsBillingContactStoreContact
                  ? store.StoreContact.FullName
                  : store.GetBillingOrDefaultContactName}
              </span>
              <br />
              <span>
                {store.IsBillingContactStoreContact
                  ? store.StoreContact.Email
                  : store.GetBillingOrDefaultContactEmail}
              </span>
            </p>
          </ClickyThingy>
        )}

        {!store ? (
          <div>
            <ContentLoader
              title="Loading store information…"
              width={160}
              height={120}
            >
              <TextBlock x={0} y={5} width={120} fontSize={12} />
              <TextBlock x={0} y={25} width={160} fontSize={12} />
              <TextBlock x={0} y={45} width={100} fontSize={12} />
              <TextBlock x={0} y={65} width={80} fontSize={12} />
              <TextBlock x={0} y={85} width={120} fontSize={12} />
              <TextBlock x={0} y={105} width={110} fontSize={12} />
            </ContentLoader>
          </div>
        ) : (
          <div>
            <p style={{ margin: 0 }}>
              <strong>{store.LocationAddress.Attention}</strong>
              <br />
              {store.LocationAddress.Line1}
              <br />
              {store.LocationAddress.Line2}
              <br />
              {store.LocationAddress.Line3}
              <br />
              {store.LocationAddress.City} {store.LocationAddress.PostCode}
              <br />
              {store.LocationAddress.Region}
              <br />
              {store.LocationAddress.Country}
              <br />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Billing;
