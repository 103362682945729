import { useCallback } from "react";
import toast from "react-hot-toast";
import { useTheme } from "styled-components";

import DS from "@design/system";

import BorderBox from "../components/BorderBox";
import Button from "../components/Button";
import MediaCard from "../components/MediaCard";
import Modal from "../components/Modal";
import ModalFooter from "../components/ModalFooter";
import ModalHeader from "../components/ModalHeader";
import ModalStandardLayout from "../components/ModalStandardLayout";
import { useRemoveStoresFromGroups } from "../state/hooks";

const RemoveStoreFromGroupModal = ({
  store,
  group,
  onConfirm,
  onClose,
}: {
  store: Api.Store;
  group: Api.TreeGroup;
  onConfirm: () => void;
  onClose: () => void;
}) => {
  const { palettes } = useTheme();

  const { mutate: removeStoreFromGroup, isPending } =
    useRemoveStoresFromGroups();

  const handleConfirm = useCallback(() => {
    removeStoreFromGroup(
      { storeIds: [store.StoreId], groupsIds: [group.GroupId] },
      {
        onError: () => {
          toast.error(
            <span>
              Unable to remove <strong>{store.Name}</strong> from{" "}
              <strong>{group.Name}</strong>.
            </span>,
          );
        },
        onSuccess: () => {
          toast.success(
            <span>
              <strong>{store.Name}</strong> was successfully removed from{" "}
              <strong>{group.Name}</strong>.
            </span>,
          );
          onConfirm();
        },
      },
    );
  }, [store, group, removeStoreFromGroup, onConfirm]);

  return (
    <Modal contentLabel="Remove store from group" onClose={onClose}>
      <ModalStandardLayout
        header={<ModalHeader title="Remove From Group" onClose={onClose} />}
        content={
          <div
            style={{
              margin: DS.margins.largeCss("rl"),
              display: "grid",
              gap: DS.margins.regular,
              alignContent: "flex-start",
            }}
          >
            <BorderBox>
              <MediaCard
                imageSize={64}
                title={store.Name}
                description={store.LocationAddress.AsOneLine}
              />
            </BorderBox>
            <p
              style={{
                margin: 0,
                color: palettes.well.foreground,
              }}
            >
              This action will remove <strong>{store.Name}</strong> from{" "}
              <strong>{group.Name}</strong>.
              <br />
              <br />
              All data is retained within the store, so no data will be lost.
              Only the association is removed.
            </p>
          </div>
        }
        footer={
          <ModalFooter>
            <p
              style={{
                margin: 0,
                textAlign: "center",
                color: palettes.well.foreground,
              }}
            >
              Are you sure you want to continue?
            </p>

            <Button disabled={isPending} onClick={onClose}>
              No, take me back!
            </Button>
            <Button
              buttonType="destructive"
              disabled={isPending}
              onClick={handleConfirm}
            >
              {isPending ? "Removing from group…" : "Yes, remove from group"}
            </Button>
          </ModalFooter>
        }
      />
    </Modal>
  );
};

export default RemoveStoreFromGroupModal;
