import { FzfResultItem } from "fzf";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import { IIcon } from "@icons";

import { activeUnitsOnly } from "./firstAidStation";

/**
 * Readiness percent to consider "low" for the readiness filter.
 *
 * Set to 25 to mimic the ReadinessImages
 */
const READINESS_CUTOFF = 25;

/**
 * Number of days to consider "recent" for days gone filter.
 *
 * Set to 29, to mimic Portal: StoreController.ts#356
 */
const DAYS_GONE_CUTOFF = 29;

export type StoresFilter = {
  lowReadyRating: boolean;
  daysGone: boolean;
  offline: boolean;
  onlyStoresWithStations: boolean;

  escalationTypes: Api.UnitReminderType[];
};

export const useStoresFilter = (): [
  StoresFilter,
  (
    filter:
      | Partial<StoresFilter>
      | ((current: StoresFilter) => Partial<StoresFilter>),
  ) => void,
] => {
  const { search } = useLocation();

  const [filters, setFilters] = useState<StoresFilter>(() => {
    const query = new URLSearchParams(search);

    return {
      lowReadyRating: !!query.get("low-ready-rating"),
      daysGone: !!query.get("days-since"),
      offline: !!query.get("offline"),
      onlyStoresWithStations: !!query.get("hide-no-stations"),

      escalationTypes: (query.get("action-types")?.split(",") ??
        []) as Api.UnitReminderType[],
    };
  });

  const setStoresFilter = useCallback(
    (
      filter:
        | Partial<StoresFilter>
        | ((current: StoresFilter) => Partial<StoresFilter>),
    ) => {
      const newFilter = typeof filter === "function" ? filter(filters) : filter;
      setFilters((currentFilter) => ({
        ...currentFilter,
        ...newFilter,
      }));
    },
    [filters],
  );

  return [filters, setStoresFilter];
};

export const filter_lowReadyRating =
  (enabled: boolean, readinesses?: { storeId: string; readiness?: number }[]) =>
  (store: FzfResultItem<Api.Store>) =>
    enabled && readinesses
      ? (readinesses.find(
          (readiness) => readiness.storeId === store.item.StoreId,
        )?.readiness ?? Infinity) < READINESS_CUTOFF
      : true;

export const filter_daysGone =
  (enabled: boolean) => (store: FzfResultItem<Api.Store>) =>
    enabled
      ? Date.now() -
          new Date(store.item.LastHighSeverityEventDate ?? 0).getTime() <
        1000 * 60 * 60 * 24 * DAYS_GONE_CUTOFF
      : true;

export const filter_offline =
  (enabled: boolean, units?: Api.Unit[]) =>
  (store: FzfResultItem<Api.Store>) =>
    enabled && units
      ? units
          .filter(activeUnitsOnly)
          .filter((unit) => unit.StoreId === store.item.StoreId)
          .filter((unit) => unit.IsActive).length > 0
      : true;

export const filter_escalations =
  (
    enabled: boolean,
    escalationTypes?: Api.UnitReminderType[],
    escalations?: Api.Escalation[],
  ) =>
  (store: FzfResultItem<Api.Store>) =>
    enabled && escalationTypes && escalations
      ? escalations
          .filter((escalation) => escalation.StoreId === store.item.StoreId)
          .filter((escalation) =>
            escalationTypes.includes(escalation.ReminderType),
          ).length > 0
      : true;

export const filter_onlyStoresWithStations =
  (enabled: boolean, units?: Api.Unit[]) =>
  (store: FzfResultItem<Api.Store>) =>
    enabled && units
      ? units
          .filter(activeUnitsOnly)
          .filter((unit) => unit.StoreId === store.item.StoreId).length > 0
      : true;

export const criticalStockOnly = (unit: Api.Unit) => {
  return (
    unit.ReplenishmentStatus === "Warehouse Packing " ||
    unit.ReplenishmentStatus === "Required " ||
    unit.IsStockCritical
  );
};

export const getStockReplenishmentStatusIcon = (
  replenishment: Api.UnitReplenishmentStatus,
): IIcon => {
  if (
    replenishment === "Warehouse Packing " ||
    replenishment === "Required " ||
    replenishment === "Warehouse Packing (Critical)"
  ) {
    return "bars";
  }
  if (
    replenishment === "Required (Critical)" ||
    replenishment === "Signed-Off, Pack-List Sent (Critical)" ||
    replenishment === "Signed-Off (Critical)"
  ) {
    return "exclamation-triangle";
  }
  if (replenishment === "") {
    return "address-card";
  } else {
    return "bell";
  }
};

export const getStockReplenishmentStatusLabel = (
  replenishment: Api.UnitReplenishmentStatus,
) => {
  switch (replenishment) {
    case "Warehouse Packing ":
    case "Required ":
      return "Stock needs refill";

    case "Required (Critical)":
    case "Signed-Off, Pack-List Sent (Critical)":
    case "Signed-Off (Critical)":
    case "Warehouse Packing (Critical)":
      return "Stock critically low";

    case "":
      return "Stock okay";

    case "Delivered ":
    case "Overdue Unpacking  (Delivered)":
    case "Overdue Unpacking (Critical) (Delivered)":
    case "Overdue Unpacking ":
    case "Overdue Unpacking (Critical)":
    case "Overdue Unpacking  (Not Delivered)":
    case "Sent In-Transit ":
    case "Sent In-Transit (Critical)":
    case "Delivered (Critical)":
    case "Unpacking ":
    case "Unpacking (Critical)":
      return "Stock in-transit";

    default:
      return "unknown";
  }
};
