import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  body {
    position: relative;
    overflow: auto;
    height: 100%;
    margin: 0;

    font-family: "${({ theme }) => theme.meta.font}", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-overflow-scrolling: touch;

    color: ${({ theme }) => theme.palettes.body.foreground};
    background: ${({ theme }) => theme.palettes.body.background};
  }

  input,
  textarea,
  button,
  select {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
  }

  code {
    font-family: "Source Code Pro", monospace;
  }

  #layers {
    z-index: 2;
  }

  .mapboxgl-map {
    font-size: 16px;
    font-family: "${({ theme }) => theme.meta.font}", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .mapboxgl-map .mapboxgl-ctrl-attrib {
    font-size: 12px;
  }
`;

const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

window.addEventListener("load", setVh);
window.addEventListener("resize", setVh);

export default GlobalStyles;
