import { AnimatePresence, motion } from "framer-motion";
import mapboxGl, {
  CircleLayer,
  GeoJSONSource,
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
} from "mapbox-gl";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MapGL, {
  Layer,
  MapRef,
  Marker,
  Source,
  ViewState,
  ViewStateChangeEvent,
} from "react-map-gl";
import { useRouteMatch } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import useResizeObserver from "use-resize-observer";

import DS from "@design/system";
import {
  useShipments,
  useEscalations,
  useStoresReadiness,
  useUnitsReadiness,
} from "@state/hooks";
import { useMapState } from "@util/MapState";
import { findCenter } from "@util/map";
import { useMapLayer } from "@util/useMapLayer";
import { useThemeHelper } from "@util/useThemeHelper";
import { MarkerMode, MarkerType } from "@util/useUserPreference";

import { trackEvent } from "../util/analytics";
import { removeDuplicates } from "../util/data";
import Button from "./Button";
import ButtonBar from "./ButtonBar";
import { ButtonSelection } from "./ButtonSelection";
import Icon from "./Icon";
import MapControls from "./MapControls";
import EscalationMarker from "./map/EscalationMarker";
import IncidentMarker from "./map/IncidentMarker";
import ReadinessMarker from "./map/ReadinessMarker";
import ShipmentMarker from "./map/ShipmentMarker";
import { UnitEscalationMarker } from "./map/UnitEscalationMarker";
import { UnitIncidentMarker } from "./map/UnitIncidentMarker";
import { UnitReadinessMarker } from "./map/UnitReadinessMarker";
import { UnitShipmentMarker } from "./map/UnitShipmentMarker";

type StoreFeatureProperties = GeoJSON.GeoJsonProperties & {
  id: string;
  name: string;

  readiness: number;
  minReadiness: number;

  lowIncidents: number;
  mediumIncidents: number;
  highIncidents: number;

  actions: number;

  shipmentsIds: string;
  unitsIds: string;

  cluster_id: number;
  point_count: number;
};

type UnitFeatureProperties = GeoJSON.GeoJsonProperties & {
  id: string;
  name: string;

  readiness: number;
  minReadiness: number;

  lowUnitIncidents: number;
  mediumUnitIncidents: number;
  highUnitIncidents: number;

  actions: number;

  shipmentsIds: string;

  unitsIds: string;

  cluster_id: number;
  point_count: number;
};

type Overlap = {
  id: string;
  featureIds: (string | number | undefined)[];
};

const PAD = 128;
const PADDING = {
  top: PAD,
  right: PAD,
  bottom: PAD,
  left: PAD + 320 + 16,
};

const featureMatch = (f1: MapboxGeoJSONFeature, f2: MapboxGeoJSONFeature) => {
  const [c1Long, c1Lat] = (f1.geometry as GeoJSON.Point).coordinates;
  const [c2Long, c2Lat] = (f2.geometry as GeoJSON.Point).coordinates;

  return c1Long === c2Long && c1Lat === c2Lat;
};

const Container = styled.div`
  height: 100%;

  background: #87bae8;

  display: flex;
  align-items: center;
  justify-content: center;

  .mapboxgl-map {
    z-index: 0;
    line-height: normal;
  }

  .mapboxgl-map + .overlays {
    z-index: 0;
  }

  .mapboxgl-map .mapboxgl-ctrl-bottom-left {
    bottom: 16px;
    left: 352px;
  }

  .mapboxgl-map .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
    margin: 0;
  }
`;

const layerStore: CircleLayer = {
  id: "store-marker",
  type: "circle",
  paint: {
    "circle-radius": 32,
    "circle-color": "transparent",
  },
};
const layerUnit: CircleLayer = {
  id: "unit-marker",
  type: "circle",
  paint: {
    "circle-radius": 32,
    "circle-color": "transparent",
  },
};

const Map = ({
  stores,
  units,
  incidents,
  selectedStoreId,
  selectedControllerSerialNumber,
  onStoreSelected,
  onUnitSelected,
}: {
  stores?: Api.Store[];
  units?: Api.Unit[];
  incidents?: Api.Incident[];
  selectedStoreId?: string;
  selectedControllerSerialNumber?: string;
  onStoreSelected?: (store: Api.Store | null) => void;
  onUnitSelected?: (unit: Api.Unit | null) => void;
}) => {
  const { t } = useTranslation();
  const match = useRouteMatch(["/group", "/store", "/all-stores"]);
  const { ref, width, height } = useResizeObserver();
  const {
    setMapRef,
    setMarkerViewState: updateSettings,
    markerState: markerView,
  } = useMapState();
  const {
    map: {
      initial: [longitude, latitude, zoom],
    },
  } = useTheme();
  const { icon, severityPalette } = useThemeHelper();

  const [viewState, setViewState] = useState<ViewState>(() => ({
    latitude,
    longitude,
    zoom: zoom,
    bearing: 0,
    pitch: 0,
    padding: PADDING,
  }));

  const [storeFeatures, setVisibleStoreFeatures] = useState<
    MapboxGeoJSONFeature[]
  >([]);
  const [unitFeatures, setVisibleUnitFeatures] = useState<
    MapboxGeoJSONFeature[]
  >([]);

  const [mapView] = useMapLayer();

  const handleButtonChange = useCallback(
    (type: MarkerType) => updateSettings({ type }),
    [updateSettings],
  );

  const handleModeChange = useCallback(
    (mode: MarkerMode) => updateSettings({ mode }),
    [updateSettings],
  );

  const storesIds = useMemo(
    () => stores?.map((store) => store.StoreId),
    [stores],
  );

  const unitsIds = useMemo(
    () => units?.map((unit) => unit.ControllerSerialNumber),
    [units],
  );

  const { readinesses } = useStoresReadiness(storesIds);
  const { readinesses: unitReadinesses } = useUnitsReadiness(unitsIds);

  const { data: escalations } = useEscalations({ storesIds });
  const { data: shipments } = useShipments({ numberDays: 90 });

  const markerClicked = useRef<MapboxGeoJSONFeature | null>(null);
  const viewportLoaded = useRef(false);
  const mapRef = useRef<MapRef | null>(null);
  if (mapRef.current) setMapRef(mapRef.current);

  const unitsJson = useMemo(
    () => ({
      type: "FeatureCollection" as const,
      features:
        units
          ?.filter((unit) => unit && unit.Longitude && unit.Latitude)
          .map((unit, i) => ({
            id: i + 1,
            type: "Feature" as const,
            geometry: {
              type: "Point" as const,
              coordinates: [unit.Longitude, unit.Latitude],
            },
            properties: {
              id: unit.ControllerSerialNumber,
              name: unit.UnitName,
              unitsIds: unit.ControllerSerialNumber,
              readiness: unitReadinesses?.find(
                (r) => r.unitId === unit.ControllerSerialNumber,
              )?.readiness,
              lowUnitIncidents:
                incidents?.filter(
                  (incident) =>
                    incident.primaryEvent?.unitName === unit.UnitName &&
                    (incident.incidentOverallSeverity ??
                      incident.severityLevel) === "Low",
                ).length ?? 0,
              mediumUnitIncidents:
                incidents?.filter(
                  (incident) =>
                    incident.primaryEvent?.unitName === unit.UnitName &&
                    (incident.incidentOverallSeverity ??
                      incident.severityLevel) === "Medium",
                ).length ?? 0,
              highUnitIncidents:
                incidents?.filter(
                  (incident) =>
                    incident.primaryEvent?.unitName === unit.UnitName &&
                    (incident.incidentOverallSeverity ??
                      incident.severityLevel) === "High",
                ).length ?? 0,
              actions:
                escalations
                  ?.filter(
                    (escalation) =>
                      !escalation.IsClosed && !escalation.IsPaused,
                  )
                  .filter(
                    (escalation): boolean =>
                      escalation.ControllerSerialNumber ===
                      unit.ControllerSerialNumber,
                  ).length ?? 0,
              shipmentsIds: shipments
                ?.filter(
                  (shipment) =>
                    shipment.ControllerSerialNumber ===
                    unit.ControllerSerialNumber,
                )
                .filter((shipment) => shipment.Status !== "Complete")
                .map((shipment) => shipment.ShipmentID)
                .join(","),
            } as UnitFeatureProperties,
          })) ?? [],
    }),
    [escalations, incidents, shipments, unitReadinesses, units],
  );

  const storesJson = useMemo(
    () => ({
      type: "FeatureCollection" as const,
      features:
        stores
          ?.filter((store) => store && store.LocationAddress)
          .map((store, i) => ({
            id: i + 1,
            type: "Feature" as const,
            geometry: {
              type: "Point" as const,
              coordinates: [
                store.LocationAddress.Longitude,
                store.LocationAddress.Latitude,
              ],
            },
            properties: {
              id: store.StoreId,
              name: store.Name,
              readiness: readinesses?.find((r) => r.storeId === store.StoreId)
                ?.readiness,
              lowIncidents:
                incidents?.filter(
                  (incident) =>
                    incident.primaryEvent?.storeName === store.Name &&
                    (incident.incidentOverallSeverity ??
                      incident.severityLevel) === "Low",
                ).length ?? 0,
              mediumIncidents:
                incidents?.filter(
                  (incident) =>
                    incident.primaryEvent?.storeName === store.Name &&
                    (incident.incidentOverallSeverity ??
                      incident.severityLevel) === "Medium",
                ).length ?? 0,
              highIncidents:
                incidents?.filter(
                  (incident) =>
                    incident.primaryEvent?.storeName === store.Name &&
                    (incident.incidentOverallSeverity ??
                      incident.severityLevel) === "High",
                ).length ?? 0,
              actions:
                escalations
                  ?.filter(
                    (escalation) =>
                      !escalation.IsClosed && !escalation.IsPaused,
                  )
                  .filter(
                    (escalation): boolean =>
                      escalation.StoreId === store.StoreId,
                  ).length ?? 0,
              shipmentsIds: shipments
                ?.filter((shipment) => shipment.StoreId === store.StoreId)
                .filter((shipment) => shipment.Status !== "Complete")
                .map((shipment) => shipment.ShipmentID)
                .join(","),
              unitsIds: units
                ?.filter((unit) => unit.StoreId === store.StoreId)
                .map((unit) => unit.ControllerSerialNumber)
                .join(","),
            } as StoreFeatureProperties,
          })) ?? [],
    }),
    [escalations, incidents, readinesses, shipments, stores, units],
  );

  const hasMatch = useMemo(() => !!match, [match]);

  const coords = useMemo(
    () =>
      units && stores
        ? markerView.type === "unit"
          ? units.map(
              (unit) =>
                [unit.Longitude, unit.Latitude] as [lng: number, lat: number],
            )
          : stores
              .filter((store) => store && store.LocationAddress)
              .map(
                (store) =>
                  [
                    store.LocationAddress.Longitude,
                    store.LocationAddress.Latitude,
                  ] as [lng: number, lat: number],
              )
        : [],
    [markerView.type, stores, units],
  );

  const zoomAll = useCallback(() => {
    if (!stores || !units || !width || !height || !mapRef.current) return;

    const { left } = mapRef.current.getPadding();
    const solved = findCenter(coords);

    if (solved)
      mapRef.current.fitBounds(solved, {
        padding: left ? undefined : PADDING,
        maxZoom: 16,
        speed: 4,
      });
  }, [height, stores, units, width, coords]);

  const handleMapMove = useCallback(
    (e: ViewStateChangeEvent) => setViewState(e.viewState),
    [],
  );

  const handleMapLoad = useCallback(() => {
    const map = mapRef.current as MapRef;

    map.on(
      "mouseenter",
      ["clustered-circle", "unclustered-circle"],
      () => (map.getCanvas().style.cursor = "pointer"),
    );

    map.on(
      "mouseleave",
      ["clustered-circle", "unclustered-circle"],
      () => (map.getCanvas().style.cursor = ""),
    );
  }, []);

  const handleMapClick = useCallback(
    (e: MapLayerMouseEvent) => {
      if (!stores || !units || !mapRef.current) return;

      const map = mapRef.current;
      const canvas = map.getCanvas();

      const feature =
        markerClicked.current ??
        map.queryRenderedFeatures(e.point, {
          layers: [markerView.type === "unit" ? "unit-marker" : "store-marker"],
        })[0];

      const store = feature
        ? stores.find(
            (s) =>
              s.StoreId === (feature.properties as StoreFeatureProperties).id,
          )
        : undefined;

      const unit = feature
        ? units.find(
            (u) =>
              u.ControllerSerialNumber ===
              (feature.properties as UnitFeatureProperties).id,
          )
        : undefined;

      if (e.originalEvent.target === canvas) {
        onStoreSelected?.(null);
        onUnitSelected?.(null);
      } else if (feature && !store && !unit) {
        const { coordinates } = feature.geometry as GeoJSON.Point;
        const { cluster_id } = feature.properties as StoreFeatureProperties;

        (
          map.getSource(
            markerView.type === "unit" ? "units" : "stores",
          ) as GeoJSONSource
        ).getClusterExpansionZoom(cluster_id, (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: coordinates as [number, number],
            zoom: zoom + 2,
            padding: PADDING,
          });
        });
      }

      markerClicked.current = null;
    },
    [markerView, onStoreSelected, onUnitSelected, stores, units],
  );

  const handleMarkerClick = useCallback(
    (feature: MapboxGeoJSONFeature) => {
      if (!stores || !units) return;

      const store = feature
        ? stores.find(
            (s) =>
              s.StoreId === (feature.properties as StoreFeatureProperties).id,
          )
        : undefined;

      const unit = feature
        ? units.find(
            (u) =>
              u.ControllerSerialNumber ===
              (feature.properties as UnitFeatureProperties).id,
          )
        : undefined;

      if (!store && !unit) {
        markerClicked.current = feature;
        return;
      } else {
        markerClicked.current = null;
      }

      onStoreSelected?.(store ? store : null);
      onUnitSelected?.(unit ? unit : null);

      trackEvent({
        category: "Map",
        action: "Clicked map pin",
        label: store?.StoreId ?? unit?.ControllerSerialNumber,
      });
    },
    [onStoreSelected, onUnitSelected, stores, units],
  );

  const updateVisibleFeatures = useCallback(() => {
    if (!mapRef.current) return [];

    const map = mapRef.current;

    const storeFeatures = removeDuplicates(
      map.querySourceFeatures("stores"),
      (feature) => String(feature.id),
    );

    const unitFeatures = removeDuplicates(
      map.querySourceFeatures("units"),
      (feature) => String(feature.id),
    );

    setVisibleStoreFeatures((current) => {
      let same = true;
      for (let i = 0; i < Math.max(current.length, storeFeatures.length); i++) {
        if (storeFeatures[i]?.id !== current[i]?.id) {
          same = false;
          break;
        }
      }

      return same ? current : storeFeatures;
    });

    setVisibleUnitFeatures((current) => {
      let same = true;
      for (let i = 0; i < Math.max(current.length, unitFeatures.length); i++) {
        if (unitFeatures[i]?.id !== current[i]?.id) {
          same = false;
          break;
        }
      }

      return same ? current : unitFeatures;
    });
  }, []);

  const markers = useMemo(() => {
    if (!mapRef.current || !stores) return;

    if (
      (!storeFeatures || !storeFeatures.length) &&
      (!unitFeatures || !unitFeatures.length)
    ) {
      return null;
    }

    const overlaps = removeDuplicates(
      storeFeatures,
      (feature) => (feature.properties as StoreFeatureProperties).id,
    ).reduce((p, feature, _i, array) => {
      const featureId = feature.id ?? 99999999;

      const [longitude, latitude] = (feature.geometry as GeoJSON.Point)
        .coordinates;

      const instances = array.filter((f) => featureMatch(f, feature));

      // No overlaps, move on.
      if (instances.length <= 1) return p;

      const coordId = `${longitude}-${latitude}`;
      const existing = p.find((c) => c.id === coordId);

      if (!existing) {
        p.push({
          id: coordId,
          featureIds: [featureId],
        });
      } else {
        const alreadyThere = existing.featureIds.find((id) => id === featureId);
        if (!alreadyThere) existing.featureIds.push(featureId);
      }

      return p;
    }, [] as Overlap[]);

    if (markerView.type === "unit") {
      return unitFeatures.map((feature) => {
        const featureId = feature.id === null ? 99999999 : feature.id;

        const [longitude, latitude] = (feature.geometry as GeoJSON.Point)
          .coordinates;

        const {
          id,
          readiness,
          minReadiness,
          point_count,
          lowUnitIncidents,
          mediumUnitIncidents,
          highUnitIncidents,
          shipmentsIds,
          actions,
        } = feature.properties as UnitFeatureProperties;

        return (
          <Marker
            key={`${String(featureId)}`}
            longitude={longitude}
            latitude={latitude}
            onClick={() => handleMarkerClick(feature)}
          >
            <UnitReadinessMarker
              unitId={id}
              selected={!!id && selectedControllerSerialNumber === id}
              rating={readiness ?? minReadiness}
              cluster={point_count}
            />

            {markerView.mode === "incidents" ? (
              <UnitIncidentMarker
                id={id}
                selected={!!id && selectedControllerSerialNumber === id}
                values={[
                  {
                    id: "low",
                    count: lowUnitIncidents,
                    color: severityPalette("Low").background,
                  },
                  {
                    id: "medium",
                    count: mediumUnitIncidents,
                    color: severityPalette("Medium").background,
                  },
                  {
                    id: "high",
                    count: highUnitIncidents,
                    color: severityPalette("High").background,
                  },
                ]}
                cluster={point_count}
              />
            ) : markerView.mode === "shipments" ? (
              <UnitShipmentMarker
                id={id}
                selected={!!id && selectedControllerSerialNumber === id}
                shipmentsIds={shipmentsIds}
                cluster={point_count}
              />
            ) : markerView.mode === "escalations" ? (
              <UnitEscalationMarker
                id={id}
                selected={!!id && selectedControllerSerialNumber === id}
                actions={actions}
                cluster={point_count}
              />
            ) : null}
          </Marker>
        );
      });
    } else if (markerView.type === "store") {
      return storeFeatures.map((feature) => {
        const featureId = feature.id ?? 99999999;

        const [longitude, latitude] = (feature.geometry as GeoJSON.Point)
          .coordinates;

        const {
          id,
          shipmentsIds,
          actions,
          lowIncidents,
          mediumIncidents,
          highIncidents,
          readiness,
          minReadiness,
          point_count,
        } = feature.properties as StoreFeatureProperties;

        const overlap = overlaps.find((overlap) =>
          overlap.featureIds.find((id) => id === featureId),
        );

        const overlapIndex =
          overlap?.featureIds.findIndex((f) => f === featureId) ?? -1;

        let ox = 0;
        let oy = 0;

        if (overlap && overlapIndex >= 0) {
          const count = overlap.featureIds.length;
          const angle = (360 / count) * overlapIndex;
          const radius = (count + 1) * 10;

          ox = Math.cos(angle * (Math.PI / 180)) * radius;
          oy = Math.sin(angle * (Math.PI / 180)) * radius;
        }

        return (
          <Marker
            key={`${String(featureId)}`}
            longitude={longitude}
            latitude={latitude}
            offset={[ox, oy]}
            onClick={() => handleMarkerClick(feature)}
            style={{
              zIndex: !!id && selectedStoreId === id ? 2 : 1,
            }}
          >
            {markerView.mode === "readiness" ? (
              <ReadinessMarker
                storeId={id}
                selected={!!id && selectedStoreId === id}
                rating={readiness ?? minReadiness}
                cluster={point_count}
              />
            ) : markerView.mode === "incidents" ? (
              <IncidentMarker
                storeId={id}
                selected={!!id && selectedStoreId === id}
                values={[
                  {
                    id: "low",
                    count: lowIncidents,
                    color: severityPalette("Low").background,
                  },
                  {
                    id: "medium",
                    count: mediumIncidents,
                    color: severityPalette("Medium").background,
                  },
                  {
                    id: "high",
                    count: highIncidents,
                    color: severityPalette("High").background,
                  },
                ]}
                cluster={point_count}
              />
            ) : markerView.mode === "escalations" ? (
              <EscalationMarker
                storeId={id}
                selected={!!id && selectedStoreId === id}
                actions={actions}
                cluster={point_count}
              />
            ) : markerView.mode === "shipments" ? (
              <ShipmentMarker
                storeId={id}
                selected={!!id && selectedStoreId === id}
                shipmentsIds={shipmentsIds}
                cluster={point_count}
              />
            ) : null}
          </Marker>
        );
      });
    }
  }, [
    stores,
    storeFeatures,
    unitFeatures,
    markerView,
    selectedControllerSerialNumber,
    severityPalette,
    handleMarkerClick,
    selectedStoreId,
  ]);

  // Subtle zoom in/out animation when opening/closing the main modal
  useEffect(() => {
    const currentZoom = mapRef.current?.getZoom() || 10;
    mapRef.current?.zoomTo(currentZoom + (hasMatch ? -0.1 : 0.1), {
      duration: 200,
    });
  }, [hasMatch]);

  // On initial load, zoom to all
  useEffect(() => {
    if (
      viewportLoaded.current ||
      !width ||
      !height ||
      !stores?.length ||
      !units?.length
    )
      return;

    zoomAll();
    viewportLoaded.current = true;
  }, [height, stores, units, width, zoomAll]);

  const mergeRefs = useCallback(
    (el: MapRef) => {
      mapRef.current = el;
      setMapRef(el);
    },
    [setMapRef],
  );

  return (
    <Container ref={ref}>
      <MapGL
        ref={mergeRefs}
        mapLib={mapboxGl}
        mapboxAccessToken={import.meta.env.VITE_APP_MAPBOX_TOKEN}
        mapStyle={mapView}
        onMove={handleMapMove}
        onLoad={handleMapLoad}
        onClick={handleMapClick}
        onRender={() => {
          updateVisibleFeatures();
        }}
        {...viewState}
        dragRotate={false}
        touchPitch={false}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <AnimatePresence initial={false}>
          <motion.div
            key="change-markers"
            style={{
              position: "absolute",

              zIndex: 1,
              top: 16,
              left: "calc(50% + (160px - 18px))", // LHS 320px / 2, RHS 36px / 2
              transform: "translateX(-50%) scale(1)",
              display: "grid",
              gridAutoFlow: "column",
              gap: DS.margins.micro,
            }}
            animate={{
              opacity: match ? 0 : 1,
              transform: match
                ? "translateX(-50%) scale(0.9)"
                : "translateX(-50%) scale(1)",
            }}
            transition={{ duration: 0.2, ease: "easeOut" }}
          >
            <ButtonBar>
              <ButtonSelection
                options={[
                  {
                    id: "store",
                    label: t("pages.map.ribbon.filter.store"),
                  },
                  {
                    id: "unit",
                    label: t("pages.map.ribbon.filter.unit"),
                  },
                ]}
                value={markerView.type}
                onChange={handleButtonChange}
              />
            </ButtonBar>
            <ButtonBar>
              <Button
                active={markerView.mode === "readiness"}
                onClick={() => handleModeChange("readiness")}
              >
                <Icon name="star" /> Readiness
              </Button>
              <Button
                active={markerView.mode === "incidents"}
                onClick={() => handleModeChange("incidents")}
              >
                <Icon name={icon("incident")} /> {t("term.incident_other")}
              </Button>
              <Button
                active={markerView.mode === "escalations"}
                onClick={() => handleModeChange("escalations")}
              >
                <Icon name="check-alt-circle" /> Actions
              </Button>
              <Button
                active={markerView.mode === "shipments"}
                onClick={() => handleModeChange("shipments")}
              >
                <Icon name="truck" /> Shipments
              </Button>
            </ButtonBar>
          </motion.div>

          <motion.div
            key="zoom-controls"
            style={{
              position: "absolute",
              top: DS.margins.regular,
              right: DS.margins.regular,
              display: "grid",
              gap: DS.margins.micro,
            }}
            animate={{
              opacity: match ? 0 : 1,
              transform: match ? "scale(0.9)" : "scale(1)",
            }}
            transition={{ duration: 0.2, ease: "easeOut" }}
          >
            <MapControls
              mapRef={mapRef.current}
              showZoomAll={true}
              zoomAllCoords={coords}
              showSatelite={true}
            />
          </motion.div>
        </AnimatePresence>

        <div style={{ position: "relative" }}>{markers}</div>

        <Source
          key="stores"
          id="stores"
          type="geojson"
          data={storesJson}
          cluster
          clusterMaxZoom={16}
          clusterRadius={48}
          clusterProperties={{
            minReadiness: ["min", ["number", ["get", "readiness"], Infinity]],
            lowIncidents: ["+", ["get", "lowIncidents"]],
            mediumIncidents: ["+", ["get", "mediumIncidents"]],
            highIncidents: ["+", ["get", "highIncidents"]],
            actions: ["+", ["get", "actions"]],
            shipmentsIds: ["concat", ["concat", ["get", "shipmentsIds"], ","]],
            unitsIds: ["concat", ["concat", ["get", "unitsIds"], ","]],
          }}
        >
          <Layer {...layerStore} />
        </Source>
        <Source
          key="units"
          id="units"
          type="geojson"
          data={unitsJson}
          cluster
          clusterMaxZoom={16}
          clusterRadius={48}
          clusterProperties={{
            minReadiness: ["min", ["number", ["get", "readiness"], Infinity]],
            lowUnitIncidents: ["+", ["get", "lowUnitIncidents"]],
            mediumUnitIncidents: ["+", ["get", "mediumUnitIncidents"]],
            highUnitIncidents: ["+", ["get", "highUnitIncidents"]],
            actions: ["+", ["get", "actions"]],
            shipmentsIds: ["concat", ["concat", ["get", "shipmentsIds"], ","]],
            unitsIds: ["concat", ["concat", ["get", "unitsIds"], ","]],
          }}
        >
          <Layer {...layerUnit} />
        </Source>
      </MapGL>
    </Container>
  );
};

export default Map;
