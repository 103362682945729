import color from "color";
import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import styled, { useTheme } from "styled-components";

import DS from "@design/system";

const MapContainer = styled.div<{ borderRadius: number }>`
  position: relative;
  overflow: hidden;
  border-radius: ${({ borderRadius }) => borderRadius}px;

  img,
  svg {
    display: block;
  }

  .mapboxgl-canvas {
    border-radius: ${({ borderRadius }) => borderRadius}px;
  }
`;

export const StoreLocationMapPlaceholder = ({
  width = 288,
  height = 144,
  borderRadius = DS.radii.itemN,
}: {
  width?: number;
  height?: number;
  borderRadius?: number;
}) => {
  const { palettes } = useTheme();

  return (
    <MapContainer borderRadius={borderRadius}>
      <ContentLoader
        foregroundColor={color(palettes.body.foreground)
          .mix(color(palettes.body.background), 0.8)
          .string()}
        backgroundColor={color(palettes.body.foreground)
          .mix(color(palettes.body.background), 0.9)
          .string()}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
      >
        <rect x={0} y={0} width={width} height={height} />
      </ContentLoader>
    </MapContainer>
  );
};

const StoreLocationMap = ({
  store,
  unit,
  marker = true,
  zoom = 14,
  width = 288,
  height = 144,
  borderRadius = DS.radii.itemN,
}: {
  store?: Api.Store;
  unit?: Api.Unit;
  marker?: boolean;
  zoom?: number;
  width?: number;
  height?: number;
  borderRadius?: number;
}) => {
  const { palettes } = useTheme();

  const [mapUrls, setMapUrls] = useState({ regular: "", highDpi: "" });

  useEffect(() => {
    if (!store && !unit) return;

    const longitude = store
      ? store.LocationAddress.Longitude
      : unit?.Longitude ?? 0;
    const latitude = store
      ? store.LocationAddress.Latitude
      : unit?.Latitude ?? 0;
    const color = palettes.signature.substring(1);

    setMapUrls(
      marker
        ? {
            regular: `https://api.mapbox.com/styles/v1/${
              import.meta.env.VITE_APP_MAPBOX_DEFAULT_STYLE
            }/static/pin-s+${color}(${longitude},${latitude})/${longitude},${latitude},${zoom},0/${width}x${height}?access_token=${
              import.meta.env.VITE_APP_MAPBOX_TOKEN
            }`,
            highDpi: `https://api.mapbox.com/styles/v1/${
              import.meta.env.VITE_APP_MAPBOX_DEFAULT_STYLE
            }/static/pin-s+${color}(${longitude},${latitude})/${longitude},${latitude},${zoom},0/${width}x${height}@2x?access_token=${
              import.meta.env.VITE_APP_MAPBOX_TOKEN
            }`,
          }
        : {
            regular: `https://api.mapbox.com/styles/v1/${
              import.meta.env.VITE_APP_MAPBOX_DEFAULT_STYLE
            }/static/${longitude},${latitude},${zoom},0/${width}x${height}?access_token=${
              import.meta.env.VITE_APP_MAPBOX_TOKEN
            }`,
            highDpi: `https://api.mapbox.com/styles/v1/${
              import.meta.env.VITE_APP_MAPBOX_DEFAULT_STYLE
            }/static/${longitude},${latitude},${zoom},0/${width}x${height}@2x?access_token=${
              import.meta.env.VITE_APP_MAPBOX_TOKEN
            }`,
          },
    );
  }, [store, width, height, marker, zoom, unit, palettes.signature]);

  return (
    <MapContainer borderRadius={borderRadius}>
      <picture>
        <source srcSet={`${mapUrls.regular}, ${mapUrls.highDpi} 2x`} />
        <img
          alt={
            store
              ? `${store.Name} is located at ${store.LocationAddress.AsOneLine}`
              : `${unit?.UnitName ?? ""} location`
          }
          src={mapUrls.regular}
          width={width}
          height={height}
        />
      </picture>
    </MapContainer>
  );
};

export default StoreLocationMap;
