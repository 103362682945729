import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useUnit } from "@state/hooks";
import { useThemeHelper } from "@util/useThemeHelper";

import { Pin, PinContent } from "./Pin";

const getValue = (value: number) => {
  if (isNaN(value) || value === Infinity) return "--";
  if (value === 0) return "0%";
  if (value === 100) return "100%";
  return `${value.toFixed(0)}%`;
};

export const UnitReadinessMarker = ({
  unitId,
  selected,
  rating,
  cluster,
}: {
  unitId: string;
  selected: boolean;
  rating: number;
  cluster?: number | null;
}) => {
  const { t } = useTranslation();
  const { readinessPalette } = useThemeHelper();

  const { data: unit } = useUnit(unitId);

  const { foreground, background } = useMemo(
    () => readinessPalette(rating),
    [rating, readinessPalette],
  );

  return (
    <Pin
      label={unit?.UnitName}
      selected={selected}
      cluster={cluster}
      content={() => (
        <PinContent
          label={<span style={{ color: foreground }}>{getValue(rating)}</span>}
          description={
            !selected && cluster ? (
              <span style={{ color: foreground }}>
                {t("map.marker.unitCount", { count: cluster })}
              </span>
            ) : (
              <span style={{ color: foreground }}>Rating</span>
            )
          }
          selected={selected}
          cluster={cluster}
        />
      )}
      background={(width, height) => (
        <rect width={width} height={height} fill={background} />
      )}
    />
  );
};
