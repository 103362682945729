import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import Button from "@components/Button";
import { FormColumn, LabelledInput } from "@components/FormControls";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import ModalStandardLayout from "@components/ModalStandardLayout";
import Scroller from "@components/Scroller";
import DS from "@design/system";
import { useRequestUnitAddressChange } from "@state/hooks";

const RequestAddressChangeModal = ({
  controllerSerialNumber,
  onClose,
}: {
  controllerSerialNumber: string;
  onClose: () => void;
}) => {
  const { register, handleSubmit } = useForm<Api.AddressChange>();

  const { mutate: requestAddressChange } = useRequestUnitAddressChange();

  const onSubmit: React.FormEventHandler = useCallback(
    (e) =>
      void handleSubmit(async (address: Api.AddressChange) => {
        return new Promise<Api.ResponseSuccess<unknown>>((resolve, reject) =>
          requestAddressChange(
            { controllerSerialNumber, address },
            { onSuccess: resolve, onError: reject },
          ),
        ).then(() => {
          onClose();
          toast.success(<span>Address change request sent</span>);
        });
      })(e),
    [handleSubmit, requestAddressChange, controllerSerialNumber, onClose],
  );

  return (
    <Modal contentLabel="Request address change" onClose={onClose}>
      <form onSubmit={onSubmit}>
        <ModalStandardLayout
          header={
            <ModalHeader title="Request Address Change" onClose={onClose} />
          }
          content={
            <Scroller>
              <div
                style={{
                  padding: `${DS.margins.regular} ${DS.margins.regular}`,
                }}
              >
                <FormColumn>
                  <LabelledInput label="Attention" {...register("Attention")} />
                  <LabelledInput label="Building" {...register("Building")} />
                  <LabelledInput
                    label="Delivery Instructions"
                    {...register("DeliveryInstructions")}
                  />

                  <LabelledInput
                    label="Address Line 1"
                    {...register("Line1")}
                  />
                  <LabelledInput
                    label="Address Line 2"
                    {...register("Line2")}
                  />
                  <LabelledInput
                    label="Address Line 3"
                    {...register("Line3")}
                  />

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "0.70fr 0.3fr",
                      gap: DS.margins.regular,
                    }}
                  >
                    <LabelledInput label="City" {...register("City")} />
                    <LabelledInput
                      label="Post Code"
                      {...register("PostCode")}
                    />
                  </div>

                  <LabelledInput label="Region" {...register("Region")} />
                  <LabelledInput label="Country" {...register("Country")} />
                </FormColumn>
              </div>
            </Scroller>
          }
          footer={
            <ModalFooter>
              <Button stretch buttonType="action" type="submit">
                Send request
              </Button>
            </ModalFooter>
          }
        />
      </form>
    </Modal>
  );
};

export default RequestAddressChangeModal;
