const en: I18n.TranslationBase = {
  lang: {
    cimode: "Debug",
    en: "English",
    mi: "Te Reo Māori",
    pt: "Portuguese",
  },
  common: {
    clearSearch: "Clear",
    actionsToDo: "Actions to do",

    userMenu: {
      editProfile: "Edit profile",
      companySettings: "Company settings",
      logOut: "Log out",
    },
  },

  pages: {
    logIn: {
      labels: {
        email: "Email",
        password: "Password",
        sharedDevice: "Shared device?",
      },

      buttons: {
        logIn: "Log in",
        loggingIn: "Logging in…",
        forgotPassword: "Forgot password?",
      },
    },
    map: {
      ribbon: {
        filter: {
          store: "Show $t(term.store_other, lowercase) only",
          unit: "Show $t(term.unit_other, lowercase) only",
        },
      },
    },
  },

  menu: {
    overview: "Overview",
    units: "$t(term.unit_other)",
    incidents: "$t(term.incident_other)",
    shipments: "Shipments",
    people: "People",
    stores: "$t(term.store_other)",
    billing: "Billing",
    settings: "Settings",
  },

  sidePanel: {
    viewGroupDetails: "View group details",
    title: "SIDEOA",
  },
  filterPanel: {
    title: "Stores",
    manageGroups: "Manage groups",
    storeCount_zero: "No stores",
    storeCount_one: "{{count}} store",
    storeCount_other: "{{count}} stores",
  },
  groupOverview: {
    title: {
      units: "$t(term.unit_other)",
      incidents: "$t(term.incident_other) over time period",
    },

    button: {
      addStoresToGroup: "Add $t(term.store_other, lowercase) to group…",
      reportDownloads: "Report downloads",
    },

    report: {
      aedReport: "$t(term.aed_one) report",
      aedReportCsv: "$t(term.aed_one) report (csv)",
      incidentStats: "$t(term.incident_one) statistics",
    },

    showLowIncidents: {
      label:
        "Show $t(severity.low, lowercase) $t(term.incident_other, lowercase)",
      on: "Yes",
      off: "No",
    },

    timePeriod: {
      label: "Time period",
      values: {
        "24hours": "24 hours",
        "7days": "7 days",
        "14days": "14 days",
        "30days": "30 days",
        "3months": "3 months",
        "6months": "6 months",
        "12months": "12 months",
      },
    },

    incidentSummary: {
      error: "There was a problem loading $t(term.incident_other, lowercase).",
      empty:
        'No $t(term.incident_other, lowercase) summary to show for last <1>$t(days.filter, {"count": {{count}} })</1>',
    },
  },
  people: {
    search: "Search for people…",
    lastLoggedIn: "Logged in",
    neverLoggedIn: "Never logged in",
    filter_zero: "(0){No people}",
    filter_one: "{{count}} person",
    filter_other: "{{count}} people",
  },
  batch: {
    filter_one: "Batch Number",
    filter_other: "Batch Numbers",
  },
  units: {
    searchPlaceholder: "Search for $t(term.unit_other, lowercase)…",

    filterByType: "Filter by $t(term.unit_one, lowercase) type",
    filterByStore: "Filter by $t(term.store_one, lowercase)",

    filter:
      "Showing <2>{{visible}} of {{count}}</2> $t(term.unit, lowercase). <6>Clear the filter</6> to see all $t(term.unit, lowercase).",

    noMatches: "No $t($t(term.unit), lowercase) matching <1>{{q}}</1> found.",

    noUnitsInStore:
      "There are no $t(term.unit_other, lowercase) in this $t(term.store_one, lowercase)",
    noUnitsInGroup: "There are no $t(term.unit_other, lowercase) in this group",
  },

  days: {
    filter_one: "24 hours",
    filter_other: "{{count}} days",
  },
  incidents: {
    search: "Search for incidents…",
    filter_zero: "No incidents",
    filter_one: "{{count}} incident",
    filter_other: "{{count}} incidents",
  },
  stores: {
    search: "Search for stores…",
    filter_zero: "No stores",
    filter_one: "{{count}} store",
    filter_other: "{{count}} stores",
  },
  addPerson: {
    title: {
      selectPeople: "Select people from the list to add to this group",
    },
  },
  editPerson: {
    tab: {
      general: "General",
      generalHelp: "General user settings.",
      stores: "Stores",
      storesHelp: "Add or remove this user from specific stores.",
      roles: "Roles",
      rolesHelp: "Add or remove roles for this user.",
      notifications: "Notifications",
      notificationsHelp:
        "Configure the events that this user will be alerted about, and how they will receive those alerts.",
    },

    label: {
      pdfReportEmail: "Email PDF Report",
    },

    // general: {},
    // stores: {},
    // roles: {},
    // notifications: {},
  },
  deleteUser: {
    associations: "store",
    associations_plural: "stores",
  },
  addOrCreatePersonWizard: {
    title: {
      select: "Select People",
      create: "New Person",
      assign: "Add to Group",
    },
  },
  global: {
    buttonLabels: {
      save: "Save",
      saveAndClose: "Save and close",
      saving: "Saving…",
      close: "Close",
    },
    searchPlaceholder: "Search…",
  },

  metrics: {
    actionsToDo: { title: "Actions to do" },

    unitStockStatus: {
      title: "$t(term.unit_one) stock status",

      caption: {
        stocked: "Stocked",
        inTransit: "In transit",
        needsRefill: "Needs refill",
        critical: "Critical",
      },
      value: {},
    },

    unitsOnline: {
      title: "$t(term.unit_other) online",

      caption: {
        noUnits: "No $t(term.unit_zero)",

        allOnline_zero:
          'No $t($t(term.unit, {"count": {{count}} }), lowercase) are online',
        allOnline_one:
          'The $t($t(term.unit, {"count": {{count}} }), lowercase) is online',
        allOnline_two:
          'Both $t($t(term.unit, {"count": {{count}} }), lowercase) are online',
        allOnline_few:
          'All $t($t(term.unit, {"count": {{count}} }), lowercase) are online',
        allOnline_many:
          'All $t($t(term.unit, {"count": {{count}} }), lowercase) are online',
        allOnline_other:
          'All $t($t(term.unit, {"count": {{count}} }), lowercase) are online',

        allOffline_zero:
          'No $t($t(term.unit, {"count": {{count}} }), lowercase) are offline',
        allOffline_one:
          'The {{count}} $t($t(term.unit, {"count": {{count}} }), lowercase) is offline',
        allOffline_two:
          'Both $t($t(term.unit, {"count": {{count}} }), lowercase) are offline',
        allOffline_few:
          'All $t($t(term.unit, {"count": {{count}} }), lowercase) are offline',
        allOffline_many:
          'All $t($t(term.unit, {"count": {{count}} }), lowercase) are offline',
        allOffline_other:
          'All $t($t(term.unit, {"count": {{count}} }), lowercase) are offline',

        someOffline: '{{count}} $t(term.unit, { "count": {{count}} }) offline',
      },

      value: {
        online: "Online",
        offline: "Offline",
      },
    },

    unitsReady: {
      title: "$t(term.unit_other) ready",

      caption: {
        noUnits: "No $t(term.unit_zero)",
        allReady:
          'All $t($t(term.unit, {"count": {{count}} }), lowercase) ready',
        allNotReady: "No $t(term.unit_other, lowercase) ready",
        someNotReady:
          '{{count}} $t($t(term.unit, { "count": {{count}} }), lowercase) not ready',
      },

      value: {
        ready: "Ready",
        notReady: "Not ready",
      },
    },

    daysSinceLastHigh: {
      title:
        "Days since last $t(severity.high, lowercase) $t(term.incident_one, lowercase)",

      tooltip:
        "Show $t(term.unit_other) with recent $t(severity.high) ${term.incident_other}",
    },

    unitReadyRating: {
      title: "Ready rating score",

      tooltip: "Show $t(term.unit_other, lowercase) with low ready rating",
    },

    storeReadyRating: {
      title: "Ready rating score",

      tooltip: "Show $t(term.store_other, lowercase) with low ready rating",
    },

    incidentsByCategory: {
      title: "$t(term.incident_other) by category",
    },

    incidentsBySeverity: {
      title: "$t(term.incident_other) by $t(term.severity_other, lowercase)",

      caption: {
        low: "$t(severity.low)",
        medium: "$t(severity.medium)",
        high: "$t(severity.high)",
      },
      value: {},
    },

    averageReadyRating: {
      title: "",
      tooltip: "",
    },

    incidentsByTimeOfDay: {
      title: "$t(term.incident_other) by time of day (12 AM–11:59 PM)",
    },
  },

  modals: {
    noPermission: {
      title: "No Permission",
      body: "You do not have permission to view this information",
    },
  },
  map: {
    marker: {
      storeCount_zero: "No $t(term.store)",
      storeCount_one: "{{count}} $t(term.store)",
      storeCount_other: "{{count}} $t(term.store)",
      unitCount_zero: "No $t(term.unit)",
      unitCount_one: "{{count}} $t(term.unit)",
      unitCount_other: "{{count}} $t(term.unit)",
    },
  },
};

export default en;
